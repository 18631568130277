export function getCancellationsT() {
        return new Promise((resolve, reject) => {
            fetch("https://www.jpadel-cms.com/api/bookings/getCancellations", {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "authorization": window.localStorage.getItem('token'),
                    },
                })
                .then((res) => {
                    resolve(res.json());
                })
                .catch((err) => reject(err));
        });
}

export function getCancelledBookingsForUser(data) {
        return new Promise((resolve, reject) => {
            fetch("https://www.jpadel-cms.com/api/bookings/getCancellationsForUser", {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "authorization": window.localStorage.getItem('token'),
                    },
                    body: JSON.stringify(data), 
                })
                .then((res) => {
                    resolve(res.json());
                })
                .catch((err) => reject(err));
        });
}
    
export function getCancellationsWDate(data) {
        return new Promise((resolve, reject) => {
                fetch("https://www.jpadel-cms.com/api/bookings/getCancellationsWDate", {
                        method: "POST",
                        headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                "authorization": window.localStorage.getItem('token'),
                        },
                        body: JSON.stringify(data),
                })
                .then((res) => {
                        resolve(res.json());
                })
                .catch((err) => reject(err));
        });
}