import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import '../../addChallenges.css'

const Feedback = (props) => {
    const [name, setName] = useState("📣 Speak Up");
    const [reward, setReward] = useState(15);
    const [duration, setDuration] = useState(30);

    const getChallenge = () => {
        return {
            id: "Feedback",
            name: name,
            description: "Submit your feedback in the rating pop up",
            points: reward,
            count: 1,
            isNew: true,
            cloudFunction: "challengeWriteFeedbackFirst15",
            order: 1,
            duration: duration,
            isVisible: true
        }
    }

    return (
        <div className="challenge-card">
            <div className="ch-card title">Feedback</div>
            <div className="ch-card row">Submit your feedback in the rating pop up.</div>
            <div className="ch-card row">Name: <input value={name} onChange={(ev) => setName(ev.target.value)} className="ch-card text"/></div>
            <div className="ch-card row">Reward: <input value={reward} onChange={(ev) => setReward(ev.target.value)} className="ch-card number" type="number"/> EGP</div>
            <div className="ch-card row">Duration: <input value={duration} onChange={(ev) => setDuration(ev.target.value)} className="ch-card number" type="number"/> days</div>
            <button className="ch-card add-button" onClick={() =>{ props.addToList(getChallenge()) }}>Add to list</button> 
        </div>
    );
}

const ManuallyTracked = (props) => {
    const [description, setDescription] = useState("");
    const [name, setName] = useState("");
    const [reward, setReward] = useState();
    const [duration, setDuration] = useState();

    const getChallenge = () => {
        const challengeType = document.querySelector('input[name="challengeType"]:checked')?.id;
        return {
            id: "Manually Tracked Challenge",
            name: name,
            description: description,
            points: parseInt(reward),
            count: 1,
            isNew: true,
            order: 1,
            duration: parseInt(duration),
            isVisible: true,
            type: challengeType
        }
    }

    function chooseChallengeType() {
        return (
          <Form>
              <div key={`inline-radio`} className="mb-3 ml-3">
                <Form.Check
                  type='radio'
                  id={'normal'}
                  label={'Normal Challenge'}
                  name={'challengeType'}
                />
                <Form.Check
                  type='radio'
                  id={'uploadPhoto'}
                  label={'Photo Challenge'}
                  name='challengeType'
                />
                <Form.Check
                  type='radio'
                  id={'uploadVideo'}
                  label={'Video Challenge'}
                  name='challengeType'
                />
              </div>
          </Form>
        );
      }

    return (
        <div className="challenge-card">
            <div className="ch-card title">Manually Tracked Challenge</div>
            <div className="ch-card row">Name: <input value={name} onChange={(ev) => setName(ev.target.value)} className="ch-card text"/></div>
            <div className="ch-card row">Description: <input value={description} onChange={(ev) => setDescription(ev.target.value)} name="hours" className="ch-card text"/></div>
            <div className="ch-card row">Reward: <input value={reward} onChange={(ev) => setReward(ev.target.value)} className="ch-card number" type="number"/> EGP</div>
            <div className="ch-card row">Duration: <input value={duration} onChange={(ev) => setDuration(ev.target.value)} className="ch-card number" type="number"/> days</div>
            <div className="ch-card row">Type: {chooseChallengeType()}</div>
            <button className="ch-card add-button" onClick={() =>{ props.addToList(getChallenge()) }}>Add to list</button> 
        </div>
    );
}

export { Feedback, ManuallyTracked };