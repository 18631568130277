import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllChallenges, modifyCourtAdmin , getChallenge, addChallenge } from "./challengesAPI";

const initialState = {
    value: [],
    status: "idle",
    progress: [],
    progressStatus: "idle",
    progressId: "",
    offset: 0,
    releaseList: [],
    addStatus: "idle",
};

export const addingChallenge = createAsyncThunk("challenges/addChallenge", async(data) => {
    const response = await addChallenge(data);
    return response;
}
);

export const retrieveChallenges = createAsyncThunk("users/getAllChallenges", async() => {
    const response = await getAllChallenges();
    return response;
});

export const retrieveChallenge = createAsyncThunk("users/getChallenge", async(data) => {
    data = {'challengeId': data};
    const response = await getChallenge(data);
    return response;
});

// export const editCourtAdmin = createAsyncThunk("users/modifyCourtAdmin", async(data) => {
//         const response = await modifyCourtAdmin(data);
//         return response;
//     }
// );

export const challengesSlice = createSlice({
    name: "challenges",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        modify: (state, action) => {
            state.progressStatus = "idle";
        },
        addToList: (state, action) => {
            if (state.addStatus === "succeeded" || state.addStatus === "failed"){
                state.addStatus = "idle";
                state.releaseList = [];
            }
            state.releaseList.push(action.payload)
        },
        removeFromList: (state, action) => {
            state.releaseList.splice(action.payload, 1);
        },
        // addLoc: (state, action) => {
        //     var newData = state.value.courtAdminData.map((user) => {
        //         if (user.uid === action.payload.uid)
        //             // console.log("Here: ", Object.assign({}, user, action.payload));
        //             return Object.assign({}, user, action.payload);
        //         return user;
        //     });
        //     state.value.courtAdminData = newData;
        // },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: {
        [retrieveChallenges.pending]: (state, action) => {
            state.status = "loading";
        },
        [retrieveChallenges.fulfilled]: (state, action) => {
            state.status = "succeeded";
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                }
            } else {
                state.value = action.payload;
            }
        },
        [retrieveChallenges.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        },

        [retrieveChallenge.pending]: (state, action) => {
            state.progressStatus = "loading";
        },
        [retrieveChallenge.fulfilled]: (state, action) => {
            state.progressStatus = "succeeded";
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.progress = []
                }
            } else {
                state.offset = 0;
                state.progress = action.payload.progress;
                state.progressId = action.payload.id;
            }
        },
        [retrieveChallenge.rejected]: (state, action) => {
            state.progressStatus = "failed";
            state.error = action.error.message;
        },
        
        [addingChallenge.pending]: (state, action) => {
            state.addStatus = "loading";
        },
        [addingChallenge.fulfilled]: (state, action) => {
            // Add any fetched posts to the array
            state.addStatus = "succeeded";
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                }
            }
        },
        [addingChallenge.rejected]: (state, action) => {
            state.addStatus = "failed";
            state.error = action.error.message;
        },
        
        // [editCourtAdmin.pending]: (state, action) => {
        //     state.editStatus = "loading";
        // },
        // [editCourtAdmin.fulfilled]: (state, action) => {
        //     state.editStatus = "succeeded";
        //     state.addStatus = false;
        //     // Add any fetched posts to the array
        //     if (action.payload.message){
        //         if (action.payload.message === "Unathorized"){
        //             state.value = []
        //         }
        //     } else {
        //         state.value = action.payload;
        //     }
        // },
        // [editCourtAdmin.rejected]: (state, action) => {
        //     state.editStatus = "failed";
        //     state.addStatus = false;
        //     state.error = action.error.message;
        // },
    },
});

export const { modify, addToList, removeFromList } = challengesSlice.actions;

export const selectAllChallenges = (state) => state.challenges.value;

export default challengesSlice.reducer;