import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {getAdminReportT, getAdminReportWDate} from "./adminReportAPI";

const initialState = {
    value: [],
    status: "idle",
    editStatus: "idle",
    startDate: new Date(),
    endDate: new Date(),
};

export const retrieveTodaysReport = createAsyncThunk("bookings/getAdminReportT", async() => {
    const response = await getAdminReportT();
    return response;
});

export const retrieveAdminReportWDate = createAsyncThunk("bookings/getAdminReportWDate", async(data) => {
    const response = await getAdminReportWDate(data);
    return response;
});


export const adminReportsSlice = createSlice({
    name: "adminReports",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        modifyDates: (state, action) => {
            console.log('This is payload: ', action.payload)
            state.startDate = action.payload[0];
            state.endDate = action.payload[1];
            //[state.startDate, state.endDate] = action.payload
        },

       
        
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: {
        [retrieveTodaysReport.pending]: (state, action) => {
            state.status = "loading";
        },
        [retrieveTodaysReport.fulfilled]: (state, action) => {
            state.status = "succeeded";
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                }
            } else {
                state.value = action.payload;
            }
            
            
        },
        [retrieveTodaysReport.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        },

        [retrieveAdminReportWDate.pending]: (state, action) => {
            state.status = "loading";
        },
        [retrieveAdminReportWDate.fulfilled]: (state, action) => {
            state.status = "succeeded";
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                }
            } else {
                state.value = action.payload;
            }
        },
    },
});

export const { modifyDates } = adminReportsSlice.actions;

export const selectAllReports = (state) => state.adminReports.value;


export default adminReportsSlice.reducer;