
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
        auth: false || window.localStorage.getItem('auth') === 'true',
        token: '',
};

export const authSlice = createSlice({
        name: "auth",
        initialState,
        // The `reducers` field lets us define reducers and generate associated actions
        reducers: {
                setAuth: (state, action) => {
                        state.auth = action.payload;
                },
                setToken: (state, action) => {
                        state.token = action.payload;
                },
        },
});

export const { setAuth, setToken } = authSlice.actions;
export default authSlice.reducer;

