import { useState } from 'react';
import '../../addChallenges.css'

const FindAPlayerJoin = (props) => {
    const [people, setPeople] = useState(6);
    const [name, setName] = useState("🦋 Social Butterfly");
    const [reward, setReward] = useState(100);
    const [duration, setDuration] = useState(30);

    const getChallenge = () => {
        return {
            id: "Find a player join",
            name: name,
            description: "Complete Find a Player games with " + people.toString() + " different players.",
            points: reward,
            count: people,
            isNew: true,
            cloudFunction: "ChallengeFindPlayerGamesWithXDifferentPlayers",
            order: 1,
            duration: duration,
            isVisible: true
        }
    }

    return (
        <div className="challenge-card">
            <div className="ch-card title">Find a Player</div>
            <div className="ch-card row">Complete Find a Player games with <input value={people} onChange={(ev) => setPeople(ev.target.value)} name="hours" className="ch-card number" type="number"/> different players.</div>
            <div className="ch-card row">Name: <input value={name} onChange={(ev) => setName(ev.target.value)} className="ch-card text"/></div>
            <div className="ch-card row">Reward: <input value={reward} onChange={(ev) => setReward(ev.target.value)} className="ch-card number" type="number"/> EGP</div>
            <div className="ch-card row">Duration: <input value={duration} onChange={(ev) => setDuration(ev.target.value)} className="ch-card number" type="number"/> days</div>
            <button className="ch-card add-button" onClick={() =>{ props.addToList(getChallenge()) }}>Add to list</button> 
        </div>
    );
}

const InvitePlayer = (props) => {
    const [name, setName] = useState("🎾 Squad");
    const [reward, setReward] = useState(40);
    const [duration, setDuration] = useState(15);

    const getChallenge = () => {
        return {
            id: "Invite players",
            name: name,
            description: "Invite 2 or more players to play with you.",
            points: reward,
            count: 1,   
            isNew: true,
            cloudFunction: "ChallengeInvitePlayers",
            order: 1,
            duration: duration,
            isVisible: true
        }
    }

    return (
        <div className="challenge-card">
            <div className="ch-card title">Invite Players</div>
            <div className="ch-card row">Invite 2 or more players to play with you.</div>
            <div className="ch-card row">Name: <input value={name} onChange={(ev) => setName(ev.target.value)} className="ch-card text"/></div>
            <div className="ch-card row">Reward: <input value={reward} onChange={(ev) => setReward(ev.target.value)} className="ch-card number" type="number"/> EGP</div>
            <div className="ch-card row">Duration: <input value={duration} onChange={(ev) => setDuration(ev.target.value)} className="ch-card number" type="number"/> days</div>
            <button className="ch-card add-button" onClick={() =>{ props.addToList(getChallenge()) }}>Add to list</button> 
        </div>
    );
}

export { FindAPlayerJoin, InvitePlayer };