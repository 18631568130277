import { Container, Row, Col, Button } from 'react-bootstrap'
import TopBar from "..//..//Components/TopBar"
//import TournamentCard from "..//..//Components/TorunamentCard"
import Loading from "..//..//Components/Loading";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { retrieveTournaments, selectAllTournaments, modify, editTournament} from './tournamentsSlice';
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import MainCard from '..//..//Components/MainCard';
import Form from "react-bootstrap/Form";
import {useState} from 'react';
import { useHistory, Link } from "react-router-dom";
import {
    Menu,
    MenuItem,
    MenuButton,
    SubMenu,

} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';


const Tournaments = () => {
debugger;
let history = useHistory();
const navigateTo = (id) => history.push({pathname:'/tournaments-table', state:{id:id}});
const handleSubmit = (e) => {
   console.log("caard");
    }

    const tournaments = useSelector(selectAllTournaments);
    const status = useSelector((state) => state.tournaments.status);
    const editStatus = useSelector((state) => state.tournaments.editStatus);

    const dispatch = useDispatch();
    useEffect(() => {
        if (status === "idle") {
            dispatch(retrieveTournaments());
        }
    }, [status, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="Tournaments">
            <TopBar title="All Tournaments"></TopBar>
            <Button className="myEditCourtButton" onClick={event => window.location.href = '/add-tournaments'}>Add Tournament</Button>

            <Container> {status === "loading" || editStatus === "loading" ? (
                <div className="loadingWrapper">
                    <Loading
                        color="#DD926A"
                        type="spin"
                        width="20%"
                        height="20%"
                    ></Loading>
                </div>
            )
                : (
                    <div className="myTournamentContent">
                        <br></br> <br></br>
                        <h2>Active Tournaments</h2>
                        <Row>
                            {tournaments.map((tournament, index) => {
                                console.log("Here");
                                console.log(tournament.tournamentName);
                                return (
                                    <Col lg={6}>
                                 <Link to={{
                                         pathname: "/tournaments-table",
                                         state: {
                                            id: tournament.id,
                                                },
                                         }}>  
                                            <MainCard size="13.5px" margin="15px" title={tournament.tournamentName} image={tournament.tournamentImage}> </MainCard>
                                            
                                        </Link>
                                    </Col>

                                );
                            })}
                        </Row>

                    </div>
                )

            }
            </Container>
        </div >
    );
}

export default Tournaments;