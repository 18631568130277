import { Row, Col, Form, Button } from 'react-bootstrap'
import React, { useState } from "react";
// import storage from '../firebase';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
//import Collapsible from 'react-collapsible';
//import { BsCaretDownFill } from "react-icons/bs";
//import {
//    Menu,
//    MenuItem,
//    MenuButton,
//    SubMenu,

//} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import {addTournamentAction} from '../Views/Tournaments/tournamentsSlice'
import { useDispatch } from "react-redux";




const AddTournamentForm = () => {
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(false);
    var urlPDF = '';
    var urlImage = '';
    var urlPDFFound = false;
    var urlImageFound = false;

    // const handleChange = () => {
    //     // Change state to the opposite (to ture) when checkbox changes
    //     setChecked(!checked);
    // };

    const [file , setFile] = useState('');
    const [image , setImage] = useState('');

    const storage = getStorage();

const uploadPDF = ()=>{
  if(file == null)
    return;
  
    const metadata = {
        contentType: 'application/pdf'
    };
    
    const pdfRef = ref(storage, `/tournaments/${file.name}`);
    const uploadTask = uploadBytesResumable(pdfRef, file, metadata);

    uploadTask.on('state_changed',
        (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case 'paused':
                    console.log('Upload is paused');
                    break;
                case 'running':
                    console.log('Upload is running');
                    break;
            }
        }, 
        (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    break;
                case 'storage/canceled':
                    // User canceled the upload
                    break;
                case 'storage/unknown':
                    // Unknown error occurred, inspect error.serverResponse
                    break;
            }
        }, 
        () => {
            // Upload completed successfully, now we can get the download URL
            console.log('Upload is completed');
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                urlPDFFound=true;
                urlPDF=downloadURL.toString();
            });
        }
    );
}

const uploadImage = ()=>{
  if(image == null)
    return;

    const metadata = {
        contentType: 'image/jpeg'
    };
    
    const imagesRef = ref(storage, `/tournaments/${image.name}`);
    const uploadTask = uploadBytesResumable(imagesRef, image, metadata);

    uploadTask.on('state_changed',
        (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case 'paused':
                    console.log('Upload is paused');
                    break;
                case 'running':
                    console.log('Upload is running');
                    break;
            }
        }, 
        (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    break;
                case 'storage/canceled':
                    // User canceled the upload
                    break;
                case 'storage/unknown':
                    // Unknown error occurred, inspect error.serverResponse
                    break;
            }
        }, 
        () => {
            // Upload completed successfully, now we can get the download URL
            console.log('Upload is completed');
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                urlImageFound=true;
                urlImage=downloadURL.toString();
            });
        }
    );      
}

    const handleSubmit = (e) => {
        e.preventDefault();
         let tournamentTagsArr = e.target.tournamentTags.value.split(',');
         for(var i=0; i<tournamentTagsArr.length; i++){
            tournamentTagsArr[i] = tournamentTagsArr[i].trim();
         }

        console.log('Trying to access checked values: ', e.target);
        debugger;
        let data = {
                priceForTwoLevels: parseInt(e.target.priceForTwoLevels.value),
                pricePerPlayer: parseInt(e.target.pricePerPlayer.value),
                prizeMoney: e.target.prizeMoney.value,
                registrationDeadline: e.target.registrationDeadline.value,
                requiresQrCode: checked,
                tournamentAddress: e.target.tournamentAddress.value,
                tournamentDateRange: e.target.tournamentDateRange.value,
                tournamentDetails: e.target.tournamentDetails.value,
                tournamentEndTime: e.target.tournamentEndTime.value,
                tournamentDrawUrl: urlPDFFound?urlPDF:e.target.tournamentDrawUrl.value,
                tournamentImage: urlImageFound?urlImage:e.target.tournamentImage.value,
                tournamentInformation: e.target.tournamentInformation.value,
                tournamentLevel: e.target.tournamentLevel.value,
                tournamentName: e.target.tournamentName.value,
                tournamentTags: tournamentTagsArr,

        };
        debugger;
        dispatch(addTournamentAction(data));
    
        console.log('Dispatch succeeded');
    };


    return (


        <Form  onSubmit={handleSubmit}>
            <Form.Group controlId="tournamentName">
                <Form.Label className="myLabel">Tournament Name</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="Tournament Name" />
            </Form.Group>

            <Form.Group controlId="tournamentImage">
                <Form.Label className="myLabel">Tournament Image</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="Tournament image URL" />
            </Form.Group>

            <input type="file"   onChange={(e)=>{setImage(e.target.files[0])}}/>
            <Button className="addCourtButton" type="button" onClick={uploadImage} variant="primary">Upload</Button>

            <Form.Group controlId="tournamentDrawUrl">
                <Form.Label className="myLabel">Tournament Draw Url</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder={urlPDFFound?'':'Tournament draw URL'}
                defaultValue={urlPDFFound?urlPDF:''} 
                />
            </Form.Group>

            <input type="file"   onChange={(e)=>{setFile(e.target.files[0])}}/>
            <Button className="addCourtButton" type="button" onClick={uploadPDF} variant="primary">Upload</Button>

            <Form.Group controlId="tournamentAddress">
                <Form.Label className="myLabel">Tournament Address</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="Tournament Address" />
            </Form.Group>

            <Form.Group controlId="tournamentInformation">
                <Form.Label className="myLabel">Tournament Information</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="Tournament Information" />
            </Form.Group>

            <Form.Group controlId="tournamentDetails">
                <Form.Label className="myLabel">Tournament Details</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="Tournament Details" />
            </Form.Group>

            <Form.Group controlId="priceForTwoLevels">
                <Form.Label className="myLabel">Price For Two Levels</Form.Label>
                <Form.Control className="myInputField" type="number" placeholder="Price for two levels" />
            </Form.Group>

            <Form.Group controlId="pricePerPlayer">
                <Form.Label className="myLabel">Price Per Player</Form.Label>
                <Form.Control className="myInputField" type="number" placeholder="Price per player" />
            </Form.Group>

            <Form.Group controlId="prizeMoney">
                <Form.Label className="myLabel">Prize Money</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="Prize Money" />
            </Form.Group>        

             <Form.Group controlId="registrationDeadline">
                <Form.Label className="myLabel">Registration Deadline</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="Please enter the registration deadline in this format: MM/DD/YYYY, HH:MM:SS PM" />
            </Form.Group>

            <Form.Group controlId="tournamentEndTime">
                <Form.Label className="myLabel">Tournament EndTime</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="Please enter the tournament end time in this format: MM/DD/YYYY, HH:MM:SS PM" />
            </Form.Group>

            <Form.Group controlId="tournamentDateRange">
                <Form.Label className="myLabel">Tournament Date Range</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="Tournament date range" />
            </Form.Group>

             <Form.Group controlId="tournamentLevel">
                <Form.Label className="myLabel">Tournament Level</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="Tournament Level" />
            </Form.Group>

             <Form.Group controlId="tournamentTags">
                <Form.Label className="myLabel">Tournament Tags</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="Enter tournament tags seperated by a comma ',' " />
            </Form.Group>

            <Form.Group className="mb-3" controlId="requiresQrCode">
            <Form.Check type="checkbox" label="Requires Qr Code?" 
            // onChange={
            //     handleChange
            // }
            />
            </Form.Group>        

            <Row>
                <Col lg={6} >
                    <Button className="myButtonFormAdd" variant="primary" type="submit">
                     Submit   
                    </Button>
                </Col>
            </Row>
        </Form>

    );
}

export default AddTournamentForm;