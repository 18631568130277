import { Row, Col, Form, Button } from 'react-bootstrap'


const EditCoachForm = () => {
    return (
        <Form>
            <Form.Group >
                <Form.Label className="myLabel">Username</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="Enter username" />
            </Form.Group>
            <Form.Group>
                <Form.Label className="myLabel">Password</Form.Label>
                <Form.Control className="myInputField" type="password" placeholder="Password" />
            </Form.Group>
            <br></br>
            <br></br>
            <Row>
                <Col className="myFormControls" lg={5}>
                    <Button className="myButtonForm" variant="primary" type="submit">
                        Submit
                    </Button>
                    <Button className="myButtonForm" variant="primary" type="submit">
                        Delete Coach
                    </Button>
                </Col>
            </Row>
        </Form>

    );
}

export default EditCoachForm;