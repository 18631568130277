import { useEffect } from "react";
import { Table, Container, Button, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { BsFillTrashFill } from "react-icons/bs";
import { FiMoreHorizontal } from "react-icons/fi";
import SearchBar from "../Components/SearchBar";
import TopBar from "../Components/TopBar";
import { BsPencil } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import {
  selectAllUsers,
  modify,
  editUser,
  retrieveUsersSlice,
  retrieveUser,
  addUser,
} from "../Views/Users/usersSlice";
import Loading from "./Loading";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const UsersTable = () => {
  const users = useSelector(selectAllUsers);
  const status = useSelector((state) => state.users.status);
  const editStatus = useSelector((state) => state.users.editStatus);
  const addStatus = useSelector((state) => state.users.addStatus);
  const offset = useSelector((state)=> state.users.offset);
  const error = useSelector((state) => state.users.error);

  var search = "";

  const dispatch = useDispatch();
  useEffect(() => {
    if (status === "idle") {
      dispatch(retrieveUsersSlice(offset));
    }
  }, [status, offset, dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      id: e.target.id,
      data: {
        fullName: e.target.fullName.value,
        email: e.target.email.value,
        mobileNumber: e.target.mobileNumber.value,
        paymentDue:
          e.target.paymentDue.value === "-"
            ? 0
            : Number.parseInt(e.target.paymentDue.value),
        wallet:
          e.target.wallet.value === "-"
            ? 0
            : Number.parseInt(e.target.wallet.value),
      },
    };

    dispatch(editUser(data));
    if (editStatus === "succeeded") dispatch(retrieveUsersSlice(offset));
  };

  const handleAdd = (e) => {
    e.preventDefault();
    if (e.target.fullName.value === "" || e.target.username.value === "" || e.target.mobileNumber.value === "" || e.target.email.value === "" || e.target.password.value === "") {
      return alert("Please fill in all the fields");
    }
    let data = {
      fullName: e.target.fullName.value,
      username: e.target.username.value,
      mobileNumber: e.target.mobileNumber.value,
      email: e.target.email.value,
      password: e.target.password.value,
    }
    dispatch(addUser(data));
    // window.location.reload(false);
  }


  const addUserForm = () => {
    return (
      <Popup
        trigger={<Button className="addCourtButton" type="button" variant="primary">Add User</Button>}
        modal
        nested
      >
        <Form id={"addUser"} onSubmit={handleAdd}>
          <Form.Group controlId="fullName">
            <Form.Label>Full Name</Form.Label>
            <Form.Control type="text" placeholder="Enter Full Name" />
          </Form.Group>
          <Form.Group controlId="username">
            <Form.Label>Username</Form.Label>
            <Form.Control type="text" placeholder="Enter Username" />
          </Form.Group>
          <Form.Group controlId="mobileNumber">
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control type="text" placeholder="Enter Mobile Number" />
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="text" placeholder="Enter Email" />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control type="text" placeholder="Enter Password" />
          </Form.Group>
        </Form>
        <button form={"addUser"} type="submit">
          Submit
        </button>
        {addStatus === "loading" &&
            <Loading
              color="#DD926A"
              type="spin"
              width="20%"
              height="20%"
            />
        }
        {addStatus === "failed" && <div style={{ color: 'red' }}>{error}</div>}
        {addStatus === "succeeded" && <div style={{ color: 'green' }}>Successfully Released</div>}
      </Popup>
    );
  };

  return (
    <div className="myUsers">
      <TopBar title="All Users"></TopBar>
      {addUserForm()}
      <br></br>       <br></br>

      <Container>
        <SearchBar 
          handleSearch={(e)=>{search = e.target.value;}} 
          handleClick={()=>{dispatch(retrieveUser(search));}} 
          keyPress={(e)=>{e.key === 'Enter' && dispatch(retrieveUser(search))}}
          placeholder="Search by Full Name, Number, or Email"></SearchBar>
        {status === "loading" || editStatus === "loading" ? (
          <div className="loadingWrapper">
            <Loading
              color="#DD926A"
              type="spin"
              width="20%"
              height="20%"
            ></Loading>
          </div>
        ) : (
          <div>
          <Table bordered hover responsive="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Username</th>
                <th>Number</th>
                <th>Email</th>
                <th>Overdue</th>
                <th>Wallet</th>
                <th>...</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{user.fullName}</td>
                    <td>{user.username? user.username: user.id}</td>
                    <td>{user.mobileNumber}</td>
                    <td>{user.email}</td>
                    <td>{user.paymentDue}</td>
                    <td>{user.wallet}</td>
                    {/* <td>
                      <Button variant="success" className="noOverdue">
                        0{" "}
                      </Button>{" "}
                    </td> */}
                    <td>
                      <Row>
                        <Col xs={4}>
                          <Popup
                            trigger={<button className="myEditIcon"> <BsPencil></BsPencil>  </button>}
                            modal
                            onClose={() => dispatch(retrieveUsersSlice(offset))}
                          >
                            <Form id={user.id} onSubmit={handleSubmit}>
                              <Form.Group controlId="fullName" size="lg">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control
                                  autoFocus
                                  type="text"
                                  defaultValue={
                                    user.fullName ? user.fullName : "-"
                                  }
                                  onChange={(val) => {
                                    let u = Object.assign({}, user, {
                                      ["fullName"]: val.target.value,
                                    });
                                    dispatch(modify(u));
                                  }}
                                />
                              </Form.Group>
                              <Form.Group controlId="email" size="lg">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                  type="email"
                                  defaultValue={user.email ? user.email : "-"}
                                  onChange={(val) => {
                                    let u = Object.assign({}, user, {
                                      ["email"]: val.target.value,
                                    });
                                    dispatch(modify(u));
                                  }}
                                />
                              </Form.Group>
                              <Form.Group controlId="mobileNumber" size="lg">
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control
                                  type="text"
                                  onChange={(val) => {
                                    let u = Object.assign({}, user, {
                                      ["mobileNumber"]: val.target.value,
                                    });
                                    dispatch(modify(u));
                                  }}
                                  defaultValue={
                                    user.mobileNumber ? user.mobileNumber : "-"
                                  }
                                />
                              </Form.Group>
                              <Form.Group controlId="paymentDue" size="lg">
                                <Form.Label>Payment Due</Form.Label>
                                <Form.Control
                                  type="text"
                                  onChange={(val) => {
                                    let u = Object.assign({}, user, {
                                      ["paymentDue"]: val.target.value,
                                    });
                                    dispatch(modify(u));
                                  }}
                                  defaultValue={
                                    user.paymentDue ? user.paymentDue : "-"
                                  }
                                />
                              </Form.Group>
                              <Form.Group controlId="wallet" size="lg">
                                <Form.Label>Wallet</Form.Label>
                                <Form.Control
                                  type="text"
                                  onChange={(val) => {
                                    let u = Object.assign({}, user, {
                                      ["wallet"]: val.target.value,
                                    });
                                    dispatch(modify(u));
                                  }}
                                  defaultValue={
                                    user.wallet ? user.wallet : "-"
                                  }
                                />
                              </Form.Group>
                            </Form>
                            <button form={user.id} type="submit">
                              Submit
                            </button>
                          </Popup>
                        </Col>
                        <Col xs={4}>
                          <BsFillTrashFill className="myDeleteIcon"></BsFillTrashFill>
                        </Col>
                        <Col xs={4}>
                          <FiMoreHorizontal className="myMoreIcon"></FiMoreHorizontal>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        <button className="submitButton" onClick={()=>dispatch(retrieveUsersSlice(offset))} type="next">
                                            Next
                                        </button>
                                        </div>
        )}
      </Container>
    </div>
  );
};

// function mapStateToProps(state) {
//   console.log('This is state: ', state );
//   const { users } = state;
//   return { allUsers: users.value };
// }

// export default connect(mapStateToProps)(UsersTable);
export default UsersTable;
