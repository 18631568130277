export function sendNotificationToAllUsers(data) {
    return new Promise((resolve, reject) => {
        fetch("https://www.jpadel-cms.com/api/notifications", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "authorization": window.localStorage.getItem('token'),
                },
                body: JSON.stringify(data),
            })
            .then((res) => {
                if (res.status === 200) {
                    resolve(res.json());
                } else {
                    reject(res.json());
                }
            })
            .catch((err) => reject(err));
    });
}