
export function getAllChallenges() {
  return new Promise((resolve, reject) => {
    fetch("https://www.jpadel-cms.com/api/challenges/all", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "authorization": window.localStorage.getItem('token'),
      },
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function getChallenge(data) {
  return new Promise((resolve, reject) => {
    fetch("https://www.jpadel-cms.com/api/challenges/single", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "authorization": window.localStorage.getItem('token'),
      },
      body:  JSON.stringify(data),
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function addChallenge(data) {
  return new Promise((resolve, reject) => {
    fetch("https://www.jpadel-cms.com/api/challenges/add", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "authorization": window.localStorage.getItem('token'),
    },
      body: JSON.stringify(data),
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}


export function modifyCourtAdmin(data) {
  return new Promise((resolve, reject) => {
    fetch("https://www.jpadel-cms.com/api/court_admins", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "authorization": window.localStorage.getItem('token'),
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

