const CoachCard = () => {
    return (
        <div className="myCoachCard">
            <div className="myCoachTooltip">
                <h1 className="myCoachTitle">Coach Name</h1>
            </div>
        </div>
    );
}

export default CoachCard;