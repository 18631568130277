import { useState } from 'react';
import Select from 'react-select'
import '../../addChallenges.css'

const BookingDaysInRow = (props) => {
    const [days, setDays] = useState(3);
    const [name, setName] = useState("💊 Junkie");
    const [reward, setReward] = useState(100);
    const [duration, setDuration] = useState(30);
    
    const getChallenge = () => {
        return {
            id: "Booking in row",
            name: name,
            description: "Complete bookings " + days.toString() + " in a row",
            points: reward,
            count: days,
            isNew: true,
            cloudFunction: "ChallengeXDaysInRow",
            order: 1,
            duration: duration,
            isVisible: true,
        }
    }

    return (
        <div className="challenge-card">
            <div className="ch-card title">Bookings Days in row</div>
            <div className="ch-card row">Complete bookings<input value={days} onChange={(ev) => setDays(ev.target.value)} name="hours" className="ch-card number" type="number"/> days in a row</div>
            <div className="ch-card row">Name: <input value={name} onChange={(ev) => setName(ev.target.value)} className="ch-card text"/></div>
            <div className="ch-card row">Reward: <input value={reward} onChange={(ev) => setReward(ev.target.value)} className="ch-card number" type="number"/> EGP</div>
            <div className="ch-card row">Duration: <input value={duration} onChange={(ev) => setDuration(ev.target.value)} className="ch-card number" type="number"/> days</div>
            <button className="ch-card add-button" onClick={() =>{ props.addToList(getChallenge())}}>Add to list</button>
        </div>
    );
}

export default BookingDaysInRow;