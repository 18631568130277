import { Row, Col } from 'react-bootstrap'
// import { VscSettingsGear } from "react-icons/vsc";
import { CgLogOut } from "react-icons/cg";


function goToLogout() {
    window.location.href = "logout";
}

const TopBar = (props) => {
    return (
        <Row className="myTopBar">
            <Col s={6}>
                <h1 className="myName">Hello!</h1>
                <h5 className="myTitle">{props.title}</h5>
            </Col>
            <Col className="myAvatarAndSettings" s={6}>
                <div className="mySettings" onClick={goToLogout}>
                    <CgLogOut className="mySettingsIcon"></CgLogOut>
                </div>
                {/* <div className="myAvatar">
                </div> */}
            </Col>
        </Row >
    );
}

export default TopBar;