export function getBookingSlice(data) {
    return new Promise((resolve, reject) => {
        fetch("https://www.jpadel-cms.com/api/bookings/getSlice", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "authorization": window.localStorage.getItem('token'),
                },
                body:  JSON.stringify(data),
            })
            .then((res) => {
                resolve(res.json());
            })
            .catch((err) => reject(err));
    });
}


export function modifyBooking(data) {
    return new Promise((resolve, reject) => {
        fetch("https://www.jpadel-cms.com/api/bookings", {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "authorization": window.localStorage.getItem('token'),
                },
                body: JSON.stringify(data),
            })
            .then((res) => {
                resolve(res.json());
            })
            .catch((err) => reject(err));
    });
  }


export function getBookingsToday(data) {
    return new Promise((resolve, reject) => {
        fetch("https://www.jpadel-cms.com/api/bookings/getToday", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "authorization": window.localStorage.getItem('token'),
                },
            })
            .then((res) => {
                resolve(res.json());
            })
            .catch((err) => reject(err));
    });
}

export function getBookingsForUser(data) {
    return new Promise((resolve, reject) => {
        fetch("https://www.jpadel-cms.com/api/bookings/forUser", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "authorization": window.localStorage.getItem('token'),
                },
                body: JSON.stringify(data), 
            })
            .then((res) => {
                resolve(res.json());
            })
            .catch((err) => reject(err));
    });
}

// export async function read_bookings_part(data){
   
// if(data.first == true){// First time to call function}
    
// try {
// let first = await db.collection('dev-bookings').orderBy('slotTime', 'desc').limit(1).get();

// let last = first.docs[first.docs.length - 1];
        
//         let bookingData = first.docs.map(doc => {
//             let booking = {id: doc.id, ...doc.data()};
//             return booking;
//             });
//             bookingData = {data: bookingData, lastDoc: last};
          
//         return bookingData;
//     } catch (err) {
//                 logger.error(`Error retrieving all booking slices documents: ${err}`);
//                 throw(err);
//                  }
//     }else{
//     try{
        
//     let querySnapshot = await db.collection('dev-bookings').orderBy('slotTime', 'desc')
//     .startAfter(data).limit(1).get();
   

//     const last = querySnapshot.docs[querySnapshot.docs.length - 1];

//         let bookingData = querySnapshot.docs.map(doc => {
//             let booking = {id: doc.id, ...doc.data()};
//             return booking;
//             });
//             bookingData = {data: bookingData, lastDoc: last};
           
//         return bookingData;
//     }catch(err){
//          logger.error(`Error retrieving all booking slices documents: ${err}`);
//                 throw(err);
//     }
//     }
// }


