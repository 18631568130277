import { Container, Row, Col, Button } from 'react-bootstrap'
import SecondaryCard from "../Components/SecondaryCard"
import TopBar from "../Components/TopBar"
import EditCoachForm from "../Components/EditCoachForm"

const EditCoach = () => {
    return (
        <div className="editCoach">
            <TopBar></TopBar>
            <Container fluid>
                <Button className="myEditCourtButton" >Edit Coach</Button>
                <br></br> <br></br>
                <Row>
                    <Col lg={12}>
                        <SecondaryCard> </SecondaryCard>
                        <br></br>
                        <EditCoachForm></EditCoachForm>
                        <br></br>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default EditCoach;