import { Jumbotron, Form, Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

const LoginForm = (props) => {

    return (
        <div className="loginForm">
            <Jumbotron className="myJumbotron">
                <Form>
                    <Form.Group controlId="email">
                        <Form.Label className="myLabel" >Username</Form.Label>
                        <Form.Control className="myInputField" type="text" placeholder="Enter username" onChange={props.onTypeE}/>
                    </Form.Group>

                    <Form.Group controlId="password">
                        <Form.Label className="myLabel">Password</Form.Label>
                        <Form.Control className="myInputField" type="password" placeholder="Password" onChange={props.onTypeP}/>
                    </Form.Group>
                    <Button className="myButton" variant="primary" onClick={props.onSubmit}>
                        Submit
                    </Button>
                    { props.loading ? <Spinner className="ml-5" animation="border" variant="light" role="status" /> : null}
                </Form>
            </Jumbotron>
        </div>
    );
}

export default LoginForm;