import '../addChallenges.css'
import {TournamentEarlyBird} from "./challenges/tournaments"

const TournamentChallenges = (props) => {

    return (
        <div>
            <div className="challenges-grid">
                <TournamentEarlyBird addToList={props.addToList} />
            </div>
        </div>
    );
}

export default TournamentChallenges;