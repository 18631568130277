import { Container, Row, Col } from 'react-bootstrap'
import Card from '../Components/Card'
import MainCard from '../Components/MainCard'
import TopBar from '../Components/TopBar'
import {getNumberOfUsers, getClicks} from './homeAPI.js'
import { useState, useEffect } from 'react';

const Home = () =>  {

    const [numberOfUsers, setNumberOfUsers] = useState(0);
    const [clicks, setClicks] = useState({});

    useEffect(async () => {
        let num = await getNumberOfUsers();
        setNumberOfUsers(num.number);

        setClicks(await getClicks());
    }, []);

    // console.log(numberOfUsers);
    // console.log(clicks);

    return (
        <div className="Home">
            <Container fluid>
                <TopBar title="Weekly Report" > </TopBar>
            </Container>
            <br></br>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card title="Total Users" body={numberOfUsers}> </Card>
                    </Col>
                    {/* <Col lg={2}>
                        <Card title="Hours Played" body="190"></Card>
                    </Col>
                    <Col lg={2}>
                        <Card title="Money Made" body="50,000"> </Card>
                    </Col>
                    <Col lg={2}>
                        <Card title="Overdue Amount" body="450"> </Card>
                    </Col>
                    <Col lg={2}>
                        <Card title="Coach Money" body="20,000"> </Card>
                    </Col>
                    <Col lg={2}>
                        <Card title="Bookings Made" body="500"> </Card>
                    </Col> */}
                </Row>
                <Row>
                {clicks[0] ? clicks.map((click) => {
                    let key, value = Object.entries(click);
                    // console.log(value[0][1]);
                    // console.log(typeof value[0][1]);
                    if (typeof value[0][1] === 'number') {
                        return (
                            <Col lg={2}>
                                <Card title={value[0][0]} body={value[0][1]}> </Card>
                            </Col>
                        )
                    } else {
                        return value[0][1].map((clk) => {
                            let key, value = Object.entries(clk);
                            // console.log(value);
                            return (
                                <Col lg={2}>
                                    <Card title={value[0][0]} body={value[0][1]}> </Card>
                                </Col>
                            )
                        })
                    }
                }) : <div></div>
                }
                </Row>
            </Container>
            <br></br>
            {/* <Container fluid>
                <Row>
                    <Col lg={4}>
                        <div onClick={event => window.location.href = '/bookings'}>
                            <MainCard title="Bookings" image="https://images.unsplash.com/photo-1530915365347-e35b749a0381?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80.jpg"> </MainCard>
                        </div>
                    </Col>

                    <Col lg={4}>
                        <div onClick={event => window.location.href = '/users'}>
                            <MainCard title="Users" image="https://images.unsplash.com/photo-1596559058872-595ea5281b7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80.jpg"> </MainCard>
                        </div>
                    </Col >
                    <Col lg={4}>
                        <div onClick={event => window.location.href = '/courts'}>
                            <MainCard title="Locations" image="https://images.unsplash.com/photo-1526888935184-a82d2a4b7e67?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80.jpg" >  </MainCard>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row>
                    <Col lg={4}>
                        <div onClick={event => window.location.href = '/tournaments'}>
                            <MainCard title="Tournaments" image="https://images.unsplash.com/flagged/photo-1576972405668-2d020a01cbfa?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1053&q=80.jpg"> </MainCard>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div onClick={event => window.location.href = '/academies'}>
                            <MainCard title="Academies" image="https://images.unsplash.com/photo-1580153111806-5007b971dfe7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=80.jpg"> </MainCard>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div onClick={event => window.location.href = '/coaches'}>
                            <MainCard title="Coaches" image="https://images.unsplash.com/photo-1554068865-24cecd4e34b8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80.jpg"> </MainCard>
                        </div>
                    </Col>
                </Row>
            </Container> */}
            <Container>
                <br></br><br></br>
            </Container>
        </div >

    );
}

export default Home;