import { useEffect } from "react";
import { Table, Container, Button, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from 'react-bootstrap/InputGroup'
import SearchBar from "../../Components/SearchBar";
import TopBar from "../../Components/TopBar";
import { RiMore2Fill } from "react-icons/ri";
import { useSelector, useDispatch } from "react-redux";
import {
  selectAllChallenges,
  modify,
  // addLoc,
  // editCourtAdmin,
  retrieveChallenge,
  retrieveChallenges,
  // addCourtAdmin
} from "./challengesSlice";
import Loading from "../../Components/Loading";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useState } from 'react';
import { useLocation } from "react-router-dom";


const ChallengesProgress = () => {

    const progress = useSelector((state) => state.challenges.progress);
    const status = useSelector((state) => state.challenges.progressStatus);
    const progressId = useSelector((state) => state.challenges.progressId);

  const location = useLocation();
  let challengeId = location.state.id;
  let challengeTitle = location.state.title;

  var search = "";
  
  //   const [type, setType] = useState('');
  const dispatch = useDispatch();
  
  useEffect(() => {
    console.log("progressId: " + progressId);
    console.log("challengeId: " + challengeId);
    if (status === "idle" || (progressId !== challengeId)) {
      dispatch(retrieveChallenge(challengeId));
    }
  }, [status, dispatch]);

//   const handleSubmit = (e) => {
//   };

  const handleAdd = (e) => {
    e.preventDefault();
    return alert("Not implemented yet!");
    // if (e.target.fullName.value === "" || e.target.username.value === "" || e.target.mobileNumber.value === "" || e.target.email.value === "" || e.target.password.value === "") {
    //   return alert("Please fill in all the fields");
    // }
    // let data = {
    //   fullName: e.target.fullName.value,
    //   username: e.target.username.value,
    //   mobileNumber: e.target.mobileNumber.value,
    //   email: e.target.email.value,
    //   password: e.target.password.value,
    // }
    // dispatch(addCourtAdmin(data));
    // window.location.reload(false);
  }

  return (
    <div className="myChallenges">
      <TopBar title={challengeTitle + ' Progress'}></TopBar>
      {console.log(challengeId)}
      {/* {addChallengeForm()} */}
      <br></br>       <br></br>
      <Container>
        <SearchBar 
          handleSearch={(e)=>{search = e.target.value;}} 
        //   handleClick={()=>{dispatch(retrieveCourtAdmin(search));}} 
        //   keyPress={(e)=>{e.key === 'Enter' && dispatch(retrieveCourtAdmin(search))}}
          placeholder="Search by Full Name, Number, or Email"></SearchBar>
        {status === "loading" ? (
          <div className="loadingWrapper">
            <Loading
              color="#DD926A"
              type="spin"
              width="20%"
              height="20%"
            ></Loading>
          </div>
        ) : (
          <div>
          <Table bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Username</th>
                <th>Progress</th>
                <th>Completed At</th>
                <th>Claimed At</th>
                <th># of days for completion</th>
                <th># of days for claiming</th>
                <th>Age</th>
                {/* <th>More Info</th> */}
              </tr>
            </thead>
            <tbody>
                {console.log(progress)}
        {
            progress ? progress.map((userProgress, index) => {
                return (
                  <tr key={index+1}>
                    <td>{index+1}</td>
                    <td>{userProgress.username}</td>
                    <td>{userProgress.progress}</td>
                    <td>{userProgress.completedAt}</td>
                    <td>{userProgress.claimedAt}</td>
                    <td>{userProgress.timeForCompletion}</td>
                    <td>{userProgress.timeBetweenCompleteAndClaim}</td>
                    <td>{userProgress.age}</td>
                    {/* <td>
                      <Link
                        to={{
                          pathname: "/challenge-progress",
                          state: {
                            id: challenge.id,
                          },
                        }}>  
                          <Button className="moreInfoButton"> <RiMore2Fill></RiMore2Fill>  </Button>
                      </Link>
                    </td> */}
                  </tr>
                );
              }) : <div></div> }
            </tbody>
          </Table>
        </div>
        )}
      </Container>
    </div>
  );
};

export default ChallengesProgress;