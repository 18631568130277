export function getAllTournaments() {
    return new Promise((resolve, reject) => {
        fetch("https://www.jpadel-cms.com/api/tournaments/all", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "authorization": window.localStorage.getItem('token'),
                },
            })
            .then((res) => {
                resolve(res.json());
            })
            .catch((err) => reject(err));
    });
}


export function modifyTournament(data) {
    return new Promise((resolve, reject) => {
        fetch("https://www.jpadel-cms.com/api/tournaments", {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "authorization": window.localStorage.getItem('token'),
                },
                body: JSON.stringify(data),
            })
            .then((res) => {
                resolve(res.json());
            })
            .catch((err) => reject(err));
    });
}

export function modifyTournamentInvitation(data) {
    return new Promise((resolve, reject) => {
        fetch("https://www.jpadel-cms.com/api/tournament_invitations", {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "authorization": window.localStorage.getItem('token'),
                },
                body: JSON.stringify(data),
            })
            .then((res) => {
                resolve(res.json());
            })
            .catch((err) => reject(err));
    });
}


export function addTournament(data) {
    return new Promise((resolve, reject) => {
        debugger;
        fetch("https://www.jpadel-cms.com/api/tournaments", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "authorization": window.localStorage.getItem('token'),
                },
                body: JSON.stringify(data),
            })
            .then((res) => {
                resolve(res.json());
            })
            .catch((err) => reject(err));
    });
}

export function getAllTournamentInvitations(data) {
    return new Promise((resolve, reject) => {
        fetch("https://www.jpadel-cms.com/api/tournament_invitations/read", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "authorization": window.localStorage.getItem('token'),
                },
                body: JSON.stringify(data),
            })
            .then((res) => {
                resolve(res.json());
            })
            .catch((err) => reject(err));
    });
}

export function getCertainTournament(data) {
    return new Promise((resolve, reject) => {
        fetch("https://www.jpadel-cms.com/api/tournaments/get", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "authorization": window.localStorage.getItem('token'),
                },
                body: JSON.stringify(data),
            })
            .then((res) => {
                resolve(res.json());
            })
            .catch((err) => reject(err));
    });
}

export function getActiveTournaments () {
    return new Promise((resolve, reject) => {
        fetch("https://www.jpadel-cms.com/api/tournaments/active", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "authorization": window.localStorage.getItem('token'),
                },
            })
            .then((res) => {
                resolve(res.json());
            })
            .catch((err) => reject(err));
    });
}

