import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {getCancellationsT, getCancelledBookingsForUser, getCancellationsWDate} from "./overdueAPI";

const initialState = {
    value: [],
    status: "idle",
    editStatus: "idle",
    startDate: new Date(),
    endDate: new Date(),
};

export const retrieveCancellationsT = createAsyncThunk("bookings/getCancellationsT", async() => {
        const response = await getCancellationsT();
        return response;
});

export const retrieveCancellationsForUser = createAsyncThunk("bookings/getCancelledBookingsForUser", async(data) => {
        data = {'search': data};
        const response = await getCancelledBookingsForUser(data);
        return response;
});
    
export const retrieveCancellationsWDate = createAsyncThunk("bookings/getCancellationsWDate", async(data) => {
        const response = await getCancellationsWDate(data);
        return response;
});

export const overdueSlice = createSlice({
        name: "overdue",
        initialState,
        // The `reducers` field lets us define reducers and generate associated actions
        reducers: {
            modifyDates: (state, action) => {
                console.log('This is payload: ', action.payload)
                state.startDate = action.payload[0];
                state.endDate = action.payload[1];
                //[state.startDate, state.endDate] = action.payload
            },
        },
        // The `extraReducers` field lets the slice handle actions defined elsewhere,
        // including actions generated by createAsyncThunk or in other slices.
        extraReducers: {
    
            [retrieveCancellationsT.pending]: (state, action) => {
                state.status = "loading";
            },
            [retrieveCancellationsT.fulfilled]: (state, action) => {
                state.status = "succeeded";
                if (action.payload.message){
                    if (action.payload.message === "Unathorized"){
                        state.value = []
                    }
                } else {
                    state.value = action.payload;
                }
            },
            [retrieveCancellationsT.rejected]: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            },

            [retrieveCancellationsForUser.pending]: (state, action) => {
                state.status = "loading";
            },
            [retrieveCancellationsForUser.fulfilled]: (state, action) => {
                state.status = "succeeded";
                if (action.payload.message){
                    if (action.payload.message === "Unathorized"){
                        state.value = []
                    }
                } else {
                    state.value = action.payload;
                }
            },
            [retrieveCancellationsForUser.rejected]: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            },


    
            [retrieveCancellationsWDate.pending]: (state, action) => {
                state.status = "loading";
            },
            [retrieveCancellationsWDate.fulfilled]: (state, action) => {
                state.status = "succeeded";
                if (action.payload.message){
                    if (action.payload.message === "Unathorized"){
                        state.value = []
                    }
                } else {
                    state.value = action.payload;
                }
            },
            [retrieveCancellationsWDate.rejected]: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            },
    
        },
});

export const { modifyDates } = overdueSlice.actions;

export const selectAllOverdues = (state) => state.overdue.value;


export default overdueSlice.reducer;

