import { initializeApp } from 'firebase/app';


const firebaseConfig = {
        apiKey: "AIzaSyDHE1fOlzBpkysSFyxPxMEh9qodvraInVw",
        authDomain: "jpadel-app.firebaseapp.com",
        projectId: "jpadel-app",
        storageBucket: "jpadel-app.appspot.com",
        messagingSenderId: "419245190735",
        appId: "1:419245190735:web:19ecec22e3004429ed043a",
        measurementId: "G-2ZBXFF5MMZ"
};

const app = initializeApp(firebaseConfig);

export default app;