import { useState } from 'react';
import '../../addChallenges.css'

const WalletTopUp = (props) => {
    const [money, setMoney] = useState(600);
    const [name, setName] = useState("💳 JPadel EPlayer");
    const [reward, setReward] = useState(50);
    const [duration, setDuration] = useState(15);

    const getChallenge = () => {
        return {
            id: "Wallet top up",
            name: name,
            description: "Top up your wallet with " + money.toString() + " EGP or more.",
            points: reward,
            count: money,   // TODO: check this
            isNew: true,
            cloudFunction: "challengeTopUpWalletWithX",
            order: 1,
            duration: duration,
            isVisible: true
        }
    }

    return (
        <div className="challenge-card">
            <div className="ch-card title">Wallet Top Up</div>
            <div className="ch-card row">Top up your wallet with <input value={money} onChange={(ev) => setMoney(ev.target.value)} name="hours" className="ch-card number" type="number"/> EGP or more.</div>
            <div className="ch-card row">Name: <input value={name} onChange={(ev) => setName(ev.target.value)} className="ch-card text"/></div>
            <div className="ch-card row">Reward: <input value={reward} onChange={(ev) => setReward(ev.target.value)} className="ch-card number" type="number"/> EGP</div>
            <div className="ch-card row">Duration: <input value={duration} onChange={(ev) => setDuration(ev.target.value)} className="ch-card number" type="number"/> days</div>
            <button className="ch-card add-button" onClick={() =>{ props.addToList(getChallenge()) }}>Add to list</button> 
        </div>
    );
}

const WalletTransfer = (props) => {
    const [name, setName] = useState("💵 Split the bill?");
    const [reward, setReward] = useState(20);
    const [duration, setDuration] = useState(30);

    const getChallenge = () => {
        return {
            id: "Wallet transfer",
            name: name,
            description: "Send or receive JPay Credit using wallet transfers",
            points: reward,
            count: 1,
            isNew: true,
            cloudFunction: "ChallengeWalletTransfers",
            order: 1,
            duration: duration,
            isVisible: true
        }
    }

    return (
        <div className="challenge-card">
            <div className="ch-card title">Wallet Transfer</div>
            <div className="ch-card row">Send or receive JPay Credit using wallet transfers</div>
            <div className="ch-card row">Name: <input value={name} onChange={(ev) => setName(ev.target.value)} className="ch-card text"/></div>
            <div className="ch-card row">Reward: <input value={reward} onChange={(ev) => setReward(ev.target.value)} className="ch-card number" type="number"/> EGP</div>
            <div className="ch-card row">Duration: <input value={duration} onChange={(ev) => setDuration(ev.target.value)} className="ch-card number" type="number"/> days</div>
            <button className="ch-card add-button" onClick={() =>{ props.addToList(getChallenge()) }}>Add to list</button> 
        </div>
    );
}

export { WalletTopUp, WalletTransfer };