import { Container } from "react-bootstrap";
import SecondaryCard from "../Components/SecondaryCard"
import TopBar from "../Components/TopBar"
import AddCoachForm from '../Components/AddCoachForm'

const AddCoach = () => {
    return (
        <div className="addCoach">
            <Container>
                <TopBar title="Add Coach"></TopBar>
                <SecondaryCard></SecondaryCard>
                <br></br> <br></br>
                <AddCoachForm></AddCoachForm>
            </Container>
        </div>
    );
}

export default AddCoach;