import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {getRatingReportT, getRatingReportWDate} from "./ratingsReportAPI";

const initialState = {
    value: [],
    status: "idle",
    editStatus: "idle",
    startDate: new Date(),
    endDate: new Date(),
};



export const retrieveRTodaysReport = createAsyncThunk("users/getRatingReport", async() => {
    const response = await getRatingReportT();
    return response;
});

export const retrieveRatingReportWDate = createAsyncThunk("users/getRatingReportWDate", async(data) => {
    const response = await getRatingReportWDate(data);
    return response;
});

export const ratingsReportsSlice = createSlice({
    name: "ratingsReports",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        modifyDates: (state, action) => {
            console.log('This is payload: ', action.payload)
            state.startDate = action.payload[0];
            state.endDate = action.payload[1];
            //[state.startDate, state.endDate] = action.payload
        },
        
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: {
        [retrieveRTodaysReport.pending]: (state, action) => {
            state.status = "loading";
        },
        [retrieveRTodaysReport.fulfilled]: (state, action) => {
            state.status = "succeeded";
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                }
            } else {
                state.value = action.payload;
            }
        },
        [retrieveRTodaysReport.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        },


        [retrieveRatingReportWDate.pending]: (state, action) => {
            state.status = "loading";
        },
        [retrieveRatingReportWDate.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.value = [];
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                }
            } else {
                state.value = action.payload;
            }
            
            
        },
        [retrieveRatingReportWDate.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        },

    },
});

export const { modifyDates } = ratingsReportsSlice.actions;

export const selectAllReports = (state) => state.ratingsReports.value;


export default ratingsReportsSlice.reducer;