import { Button, Row, Col } from 'react-bootstrap'
import MainCard from '..//..//Components/MainCard';
import TopBar from '..//..//Components/TopBar';
import { retrieveLocations, selectAllLocations, modify, editLocation, changeRequireId, changePriceAllBool } from './locationsSlice';
import Loading from "..//..//Components/Loading";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Form from "react-bootstrap/Form";
import Collapsible from 'react-collapsible';
import { BsCaretDownFill } from "react-icons/bs";
import '@szhsin/react-menu/dist/index.css';
import Select from 'react-select'


const CourtView = () => {

    const locations = useSelector(selectAllLocations);
    const status = useSelector((state) => state.locations.status);
    const editStatus = useSelector((state) => state.locations.editStatus);
    const requiresIdCheck = useSelector((state) => state.locations.requireIdcheck);
    const priceAllBool = useSelector((state)=> state.locations.priceAllBool);

    const [courtsToChangePrice, setCourtsToChangePrice] = useState([]);
    const [daysToChangePrice, setDaysToChangePrice] = useState([]);

    const daysOptions = [
        { value: '0', label: 'Mon' },
        { value: '1', label: 'Tues' },
        { value: '2', label: 'Wed' },
        { value: '3', label: 'Thu' },
        { value: '4', label: 'Fri' },
        { value: '5', label: 'Sat' },
        { value: '6', label: 'Sun' },
    ];

    const dispatch = useDispatch();
    useEffect(() => {
        if (status === "idle") {
            dispatch(retrieveLocations());
        }
    }, [status, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = (e) => {
        e.preventDefault();
        
        const pricePerCourtAppend = {}

        if (!priceAllBool){
            const toPrice = e.target.pricePerCourt[0].value;
            const fromTime = parseInt(e.target.pricePerCourt[1].value);
            const toTime = parseInt(e.target.pricePerCourt[2].value);

            for (var court of courtsToChangePrice) {
                for (var day of daysToChangePrice) {
                    for (var i=fromTime; i<=toTime; i++) {
                        const st = [`pricePerCourt.${court}.${day}.${i.toString()}`];
                        pricePerCourtAppend[st] = parseInt(toPrice);
                    }
                }
            }

            console.log('Price per court append: ', pricePerCourtAppend);
        }


        var courtNames;
        for(var loc in locations){
            if(locations[loc] && locations[loc].id === e.target.id){
            courtNames = locations[loc].courtNames;
            }
        }

        let data = {
            id: e.target.id,
            data: {
                locationName: e.target.locationName.value,
                closingHours: {
                    "1": parseInt(e.target.Mon.value),
                    "2": parseInt(e.target.Tue.value),
                    "3": parseInt(e.target.Wed.value),
                    "4": parseInt(e.target.Thu.value),
                    "5": parseInt(e.target.Fri.value),
                    "6": parseInt(e.target.Sat.value),
                    "7": parseInt(e.target.Sun.value)
                },
                openingTime: parseInt(e.target.openingTime.value),
                requiresUserId: e.target.requiresIdValue.checked,
                priceForAllCourts: (e.target.priceForAllCourts)? parseInt(e.target.priceForAllCourts.value) : 300,
                courtNames: courtNames,
                priceAllBool: priceAllBool,

            },
        };
        data.data = {...data.data, ...pricePerCourtAppend}
        dispatch(editLocation(data));

        dispatch(retrieveLocations());
        console.log('Dispatch succeeded');
    };

    const handleChange = (e, i) => {
        console.log('This is target: ', e.target.checked);
        let u = {
            check: e.target.checked,
            index: i
        };
        dispatch(changeRequireId(u));
    }

    const handlePriceAll = (e) =>{
        console.log('Insinde handle Price all', priceAllBool)
        dispatch(changePriceAllBool(e.target.checked));
    }

    const options = (location) => {
        return location.courtNames.map((court, i) => {
            return { value: court, label: court }
        });
    }

    const checkTimeRange = (e) => {

        if (e.target.value > 23 || e.target.value < 0) {
            e.target.value = 0;
        } else {
            let time = e.target.value.toString();
            if (time.length !== 0 && time.length !== 1 && e.target.value < 10) {
                e.target.value = parseInt(time[1]);
            }
        }
        
    }

    const selectedCourts = (value) => {
        let selectedCourts = value.map((court) => {
            return court.value;
        });
        setCourtsToChangePrice(selectedCourts);
    }

    const selectedDays = (value) => {
        let selectedDays = value.map((day) => {
            return day.value;
        });
        setDaysToChangePrice(selectedDays);
    }


    return (
        <div>
            <TopBar title="All Courts"></TopBar>
            <Button className="addCourtButton" type="button" variant="primary" onClick={event => window.location.href = '/add-courts'}>Add Court</Button>

            {status === "loading" || editStatus === "loading" ? (
                <div className="loadingWrapper">
                    <Loading
                        color="#DD926A"
                        type="spin"
                        width="20%"
                        height="20%"
                    ></Loading>
                </div>
            )
                : (<div className="myCourtsCards">
                    <Row>
                        {locations.map((location, index) => {
                            
                            if (location === null) return;
                            
                            return (

                                <Col lg={4}>
                                    <Popup
                                        trigger={
                                            <div>
                                                <MainCard size="13.5px" margin="15px" title={location.locationName} image={location.locationImage}> </MainCard>
                                            </div>}
                                        modal
                                        onClose={() => dispatch(retrieveLocations())}
                                    >
                                        <Form className="editForm" id={location.id} onSubmit={handleSubmit}>
                                            <Form.Group as={Row} controlId="locationName" size="lg">
                                                <Form.Label column sm="3" className="font-weight-bold">Location Name</Form.Label>
                                                <Col>
                                                    <Form.Control
                                                        autoFocus
                                                        type="text"
                                                        defaultValue={
                                                            location.locationName ? location.locationName : "-"
                                                        }
                                                        onChange={(val) => {
                                                            let u = Object.assign({}, location, {
                                                                ["locationName"]: val.target.value,
                                                            });
                                                            dispatch(modify(u));
                                                        }}
                                                    />
                                                </Col>
                                            </Form.Group>
                                             <Form.Group controlId="priceForAllBool" size="lg">
                                                <Form.Check
                                                    className="font-weight-bold"
                                                    type="checkbox"
                                                    id="requiresIdValue"
                                                    label="Change Price for all courts at all times?"
                                                    defaultChecked= {false}
                                                    onChange= {(e) => {
                                                        handlePriceAll(e);
                                                    }}
                                                />
                                            </Form.Group>

                                            {priceAllBool ? (
                                                <Form.Group controlId="priceForAllCourts">
                                                <Row className="myRow" sm={2}>
                                                    <Col sm={6}><Form.Label className="myLabel">‣ Price For All Courts</Form.Label></Col>
                                                    <Col sm={6}><Form.Control className="myInputField border" type="number" placeholder="priceForAllCourts"/></Col>
                                                </Row>
                                                 </Form.Group>
                                            ) : (
                                            <Form.Group as={Row} controlId="pricePerCourt" size="lg">
                                                <Row className="myRow" sm={4}>
                                                    <Col sm={4}><Form.Label className="myLabel">‣ Change price for courts </Form.Label></Col>
                                                    <Col sm={4}><Select options={options(location)} isMulti={true} onChange={selectedCourts}/></Col>
                                                    <Col sm={1}><Form.Label> to </Form.Label></Col>
                                                    <Col sm={3}><Form.Control type="number" placeholder="pricePerCourt" className="border"/></Col>
                                                </Row>
                                                <Row className="myRow" sm={4}>
                                                    <Col sm={2}><Form.Label> from time </Form.Label></Col>
                                                    <Col sm={3}><Form.Control type="number" placeholder="fromTime" className="border" onChange={checkTimeRange}/></Col>
                                                    <Col sm={2}><Form.Label> to time </Form.Label></Col>
                                                    <Col sm={3}><Form.Control type="number" placeholder="toTime" className="border" onChange={checkTimeRange}/></Col>
                                                </Row>
                                                <Row className="myRow" sm={2}>
                                                    <Col sm={2}><Form.Label> on days </Form.Label></Col>
                                                    <Col sm={6}><Select options={daysOptions} isMulti={true} onChange={selectedDays}/></Col>
                                                </Row>

                                        </Form.Group>)}

                                            <Form.Group controlId="closingHours" size="lg">
                                                <Collapsible trigger={<div><Form.Label className="font-weight-bold">Closing Hours <BsCaretDownFill> </BsCaretDownFill> </Form.Label></div>}>
                                                    <Form.Group as={Row} controlId="Sat" size="sm">
                                                        <Form.Label column sm="2">
                                                            Saturday
                                                        </Form.Label>
                                                        <Col>
                                                            <Form.Control
                                                                type="text"
                                                                defaultValue={
                                                                    location.closingHours[6] ? location.closingHours[6] : 0
                                                                }
                                                                onChange={(val) => {
                                                                    let u = Object.assign({}, location, {
                                                                        ["closingHours"]: {
                                                                            ...location.closingHours,
                                                                            "6": parseInt(val.target.value)
                                                                        },
                                                                    });
                                                                    dispatch(modify(u));
                                                                }}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="Sun" size="sm">
                                                        <Form.Label column sm="2">
                                                            Sunday
                                                        </Form.Label>

                                                        <Col>
                                                            <Form.Control
                                                                type="text"
                                                                defaultValue={
                                                                    location.closingHours[7] ? location.closingHours[7] : 0
                                                                }

                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="Mon" size="sm">
                                                        <Form.Label column sm="2">
                                                            Monday
                                                        </Form.Label>

                                                        <Col>
                                                            <Form.Control
                                                                type="text"
                                                                defaultValue={
                                                                    location.closingHours[1] ? location.closingHours[1] : 0
                                                                }

                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="Tue" size="sm">
                                                        <Form.Label column sm="2">
                                                            Tuesday
                                                        </Form.Label>

                                                        <Col>
                                                            <Form.Control
                                                                type="text"
                                                                defaultValue={
                                                                    location.closingHours[2] ? location.closingHours[2] : 0
                                                                }

                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="Wed" size="sm">
                                                        <Form.Label column sm="2">
                                                            Wednesday
                                                        </Form.Label>

                                                        <Col>
                                                            <Form.Control
                                                                type="text"
                                                                defaultValue={
                                                                    location.closingHours[3] ? location.closingHours[3] : 0
                                                                }

                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="Thu" size="sm">
                                                        <Form.Label column sm="2">
                                                            Thursday
                                                        </Form.Label>

                                                        <Col>
                                                            <Form.Control
                                                                type="text"
                                                                defaultValue={
                                                                    location.closingHours[4] ? location.closingHours[4] : 0
                                                                }

                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="Fri" size="sm">
                                                        <Form.Label column sm="2">
                                                            Friday
                                                        </Form.Label>

                                                        <Col>
                                                            <Form.Control
                                                                type="text"
                                                                defaultValue={
                                                                    location.closingHours[5] ? location.closingHours[5] : 0
                                                                }

                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                </Collapsible>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="openingTime" size="lg">
                                                <Form.Label column sm="3" className="font-weight-bold">Opening Time</Form.Label>
                                                <Col>
                                                    <Form.Control
                                                        type="text"
                                                        defaultValue={
                                                            location.openingTime ? location.openingTime : 0
                                                        }
                                                    />
                                                </Col>
                                            </Form.Group>


                                            <Form.Group controlId="requiresUserId" size="lg">
                                                <Form.Check
                                                    type="checkbox"
                                                    id="requiresIdValue"
                                                    label="Does this place require id?"
                                                    defaultChecked={location.requiresUserId}
                                                    onChange={(e) => {
                                                        handleChange(e, index);
                                                    }}
                                                />
                                            </Form.Group>

                                            {requiresIdCheck[index] ? (
                                                <Form.Group as={Row} size="lg">
                                                    <Form.Label column sm="3">Id Start Time:</Form.Label>
                                                    <Col>
                                                        <Form.Control

                                                            type="text"
                                                            id="requiresIdStart"
                                                            defaultValue={
                                                                location.requiresIdStart ? location.requiresIdStart : "-"
                                                            }
                                                            onChange={(val) => {
                                                                let u = Object.assign({}, location, {
                                                                    ["requiresIdStart"]: val.target.value,
                                                                });
                                                                dispatch(modify(u));
                                                            }}
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="3">Id End Time:</Form.Label>
                                                    <Col>
                                                        <Form.Control

                                                            type="text"
                                                            id="requiresIdEnd"
                                                            defaultValue={
                                                                location.requiresIdEnd ? location.requiresIdEnd : "-"
                                                            }
                                                            onChange={(val) => {
                                                                let u = Object.assign({}, location, {
                                                                    ["requiresIdEnd"]: val.target.value,
                                                                });
                                                                dispatch(modify(u));
                                                            }}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            ) : (<div></div>)}
                                        </Form>
                                        <button className="submitButton" form={location.id} type="submit">
                                            Submit
                                        </button>
                                    </Popup>
                                </Col>
                            );
                        })}
                    </Row>
                </div>
                )
            }
        </div >
    );
}

export default CourtView;
