import OverdueTable from "../Components/OverdueTable"


const Overdue = () => {
    return (
        <div className="myOverdue">
            <OverdueTable> </OverdueTable>
        </div>
    );
}

export default Overdue;