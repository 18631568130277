import { Container, Row, Col } from 'react-bootstrap'
import SecondaryCard from "../Components/SecondaryCard"
import TopBar from "../Components/TopBar"
import EditCourtForm from "../Components/EditCourtForm"



const EditCourt = () => {
    return (
        <div className="EditCourtsView">
            <TopBar></TopBar>
            <Container fluid>
                <br></br> <br></br>
                <Row>
                    <Col lg={12}>
                        <SecondaryCard> </SecondaryCard>
                        <br></br>
                        <EditCourtForm></EditCourtForm>
                        <br></br>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default EditCourt;