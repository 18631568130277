import '../addChallenges.css'
import BookingHour from "./challenges/booking_hour"
import BookingAtLocation from "./challenges/booking_at_location"
import BookingBeforeHour from "./challenges/booking_before_hour"
import BookingDaysInRow from "./challenges/booking_days_in_row"
import BookingDiffLocations from "./challenges/booking_different_locations"

const BookingChallenges = (props) => {
    return (
        <div>
            <div className="challenges-grid">
                <BookingHour addToList={props.addToList} />
                <BookingAtLocation addToList={props.addToList} />
                <BookingBeforeHour addToList={props.addToList} />
                <BookingDaysInRow addToList={props.addToList} />
                <BookingDiffLocations addToList={props.addToList} />
            </div>
        </div>
    );
}

export default BookingChallenges;