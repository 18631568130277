import { Container } from "react-bootstrap";
import AddCourtForm from "../Components/AddCourtForm";
import TopBar from "../Components/TopBar";

const AddCourt = () => {
    return (
        <Container>
            <TopBar title="Add Court"></TopBar>
            <br></br>
            <AddCourtForm></AddCourtForm>
        </Container>
    );
}

export default AddCourt;