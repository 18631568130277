const Card = (props) => {
    return (
        <div className="myCard">
            <h6 className="myCardContentTitle">
                {props.title}
            </h6>
            <h1 className="myCardContentInfo">{props.body}
            </h1>
        </div>
    );
}

export default Card;
