import { BsFillTrashFill } from "react-icons/bs";

const MainCard = (props) => {
    return (
        <div className="myMainCard" style={{
            backgroundImage: "url(" + props.image + ")",
        }}>
            <div className="myToolTip">
                <h4 className="myMainCardTitle" style={{ fontSize: props.size, marginTop: props.margin }}>{props.title}</h4>
                <div className="myDeleteToolTip">
                    <div className="myTrashIcon" onClick="">
                        <BsFillTrashFill></BsFillTrashFill>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default MainCard;
