
import TopBar from "../Components/TopBar"
import EditTorunamentForm from "../Components/EditTournamentForm";
import TournamentCard from "../Components/TorunamentCard";
import MainCard from '../Components/MainCard';
import { useLocation } from 'react-router-dom';

const EditTournaments = () => {
    const location = useLocation();
    debugger;
    const str = location.state.id;
    debugger;
   
   
   return (
       <div className="editTournaments">
            <TopBar></TopBar>
            <div>
            <EditTorunamentForm>{str}</EditTorunamentForm>
            </div>
        </div>
    );
}

export default EditTournaments;