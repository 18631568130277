import '../addChallenges.css'
import {FindAPlayerJoin, InvitePlayer} from "./challenges/find_a_player"

const FindAPlayerChallenges = (props) => {

    return (
        <div>
            <div className="challenges-grid">
                <FindAPlayerJoin addToList={props.addToList} />
                <InvitePlayer addToList={props.addToList} />
            </div>
        </div>
    );
}

export default FindAPlayerChallenges;