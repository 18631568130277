import { useEffect } from "react";
import { Table, Container, Button, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from 'react-bootstrap/InputGroup'
import SearchBar from "../../Components/SearchBar";
import TopBar from "../../Components/TopBar";
import { RiMore2Fill } from "react-icons/ri";
import { useSelector, useDispatch } from "react-redux";
import {
  selectAllChallenges,
  // modify,
  // addLoc,
  // editCourtAdmin,
  retrieveChallenge,
  retrieveChallenges,
  modify,
  // addCourtAdmin
} from "./challengesSlice";
import Loading from "../../Components/Loading";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useState } from 'react';
import { Link } from "react-router-dom";


const Challenges = () => {

  const challenges = useSelector(selectAllChallenges);
  const status = useSelector((state) => state.challenges.status);
  const offset = useSelector((state)=> state.challenges.offset);

  var search = "";

  const [type, setType] = useState('');

  const dispatch = useDispatch();
  useEffect(() => {
    if (status === "idle") {
      dispatch(retrieveChallenges());
    }
  }, [status, offset, dispatch]);

  return (
    <div className="myChallenges">
      <TopBar title="All Challenges"></TopBar>
      <Button className="myEditCourtButton" onClick={event => window.location.href = '/add-challenges'}>Add Challenges</Button>
      <br></br>       <br></br>
      <Container>
        {/* <SearchBar 
          handleSearch={(e)=>{search = e.target.value;}} 
          handleClick={()=>{dispatch(retrieveCourtAdmin(search));}} 
          keyPress={(e)=>{e.key === 'Enter' && dispatch(retrieveCourtAdmin(search))}}
          placeholder="Search by Full Name, Number, or Email"></SearchBar> */}
        {status === "loading" ? (
          <div className="loadingWrapper">
            <Loading
              color="#DD926A"
              type="spin"
              width="20%"
              height="20%"
            ></Loading>
          </div>
        ) : (
          <div>
          <Table bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Description</th>
                <th>End Time</th>
                <th># Attempted</th>
                <th># Completed</th>
                <th># Claimed</th>
                <th>Avg days for completion</th>
                <th>Avg days for claiming</th>
                <th>Avg progress points</th>
                <th>More Info</th>
                {/* <th>Detailed User</th> */}
              </tr>
            </thead>
            <tbody>
              {/* {console.log(challenges)} */}
        {
            challenges ? challenges.map((challenge, index) => {
                return (
                  <tr key={index+1}>
                    <td>{index+1}</td>
                    <td>{challenge.title}</td>
                    <td>{challenge.description}</td>
                    <td>{challenge.endTime}</td>
                    <td>{challenge.count}</td>
                    <td>{challenge.completed}</td>
                    <td>{challenge.claimed}</td>
                    <td>{challenge.avgDaysToComplete}</td>
                    <td>{challenge.avgTimeToClaim}</td>
                    <td>{challenge.avgProgress}</td>
                    <td>
                      {/* {dispatch(modify())} */}
                      <Link
                        to={{
                          pathname: "/challenge-progress",
                          state: {
                            id: challenge.id,
                            title: challenge.title,
                            reload: true,
                          },
                        }}>  
                          <Button className="moreInfoButton"> <RiMore2Fill></RiMore2Fill>  </Button>
                      </Link>
                    </td>
                  </tr>
                );
              }) : <div></div> }
            </tbody>
          </Table>
        </div>
        )}
      </Container>
    </div>
  );
};

export default Challenges;