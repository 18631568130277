
export function getAllCourtAdmins() {
  return new Promise((resolve, reject) => {
    fetch("https://www.jpadel-cms.com/api/court_admins/all", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "authorization": window.localStorage.getItem('token'),
      },
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function addingCourtAdmin(data) {
  return new Promise((resolve, reject) => {
    fetch("https://www.jpadel-cms.com/api/users/addCourtAdmin", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "authorization": window.localStorage.getItem('token'),
      },
      body: JSON.stringify(data),
    })
      .then(async (res) => {
        if (res.status === 200) {
          resolve(await res.json());
        } else {
          reject(await res.json());
        }
      })
      .catch((err) => reject(err));
  });
}

export function getUser(data) {
  return new Promise((resolve, reject) => {
    fetch("https://www.jpadel-cms.com/api/users/single", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "authorization": window.localStorage.getItem('token'),
      },
      body:  JSON.stringify(data),
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function modifyCourtAdmin(data) {
  return new Promise((resolve, reject) => {
    fetch("https://www.jpadel-cms.com/api/court_admins", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "authorization": window.localStorage.getItem('token'),
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

