export function getAllAcademies() {
    return new Promise((resolve, reject) => {
        fetch("https://www.jpadel-cms.com/api/academies/all", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "authorization": window.localStorage.getItem('token'),
                },
            })
            .then((res) => {
                resolve(res.json());
            })
            .catch((err) => reject(err));
    });
}


export function modifyAcademy(data) {
    return new Promise((resolve, reject) => {
        fetch("https://www.jpadel-cms.com/api/academies", {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "authorization": window.localStorage.getItem('token'),
                },
                body: JSON.stringify(data),
            })
            .then((res) => {
                resolve(res.json());
            })
            .catch((err) => reject(err));
    });
}