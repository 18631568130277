import { Container, Col, Row } from "react-bootstrap"
import { retrieveAcademies, selectAllAcademies, } from './academiesSlice'
import Loading from "..//..//Components/Loading";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "reactjs-popup/dist/index.css";
import MainCard from "..//..//Components/MainCard"
import TopBar from "..//..//Components/TopBar"


const Acadmeies = () => {

    const academies = useSelector(selectAllAcademies);
    const status = useSelector((state) => state.academies.status);
    const editStatus = useSelector((state) => state.academies.editStatus);

    const dispatch = useDispatch();
    useEffect(() => {
        if (status === "idle") {
            dispatch(retrieveAcademies());
        }
    }, [status, dispatch]);

    return (


        <div className="myAcademies" >

            <TopBar title="All Academies"></TopBar>
            {status === "loading" || editStatus === "loading" ? (
                <div className="loadingWrapper">
                    <Loading
                        color="#DD926A"
                        type="spin"
                        width="20%"
                        height="20%"
                    ></Loading>
                </div>
            )
                : (<div className="myAcademiesCard">
                    <Container>
                        <Row>
                            {academies.map((academy, index) => {
                                return (
                                    <Col lg={6}>
                                        <MainCard title={academy.academyName}></MainCard>
                                    </Col>
                                );
                            })}
                            <Col lg={6}>
                                <MainCard title="Smash Academy"></MainCard>
                            </Col >
                        </Row>
                    </Container>
                </div>)
            }
        </div>
    );
}

export default Acadmeies;