export function getAdminReportT() {
    return new Promise((resolve, reject) => {
        fetch("https://www.jpadel-cms.com/api/bookings/getAdminReport", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "authorization": window.localStorage.getItem('token'),
                },
            })
            .then((res) => {
                resolve(res.json());
            })
            .catch((err) => reject(err));
    });
}

export function getAdminReportWDate(data) {
    return new Promise((resolve, reject) => {
        fetch("https://www.jpadel-cms.com/api/bookings/getAdminReportWDate", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "authorization": window.localStorage.getItem('token'),
                },
             body: JSON.stringify(data),
            })
            .then((res) => {
                resolve(res.json());
            })
            .catch((err) => reject(err));
    });
}

export function generateExcelReport(data) {
    return new Promise((resolve, reject) => {
        fetch("https://www.jpadel-cms.com/api/reports/generate", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "authorization": window.localStorage.getItem('token'),
                },
             body: JSON.stringify(data),
            })
            .then((res) => {
                resolve(res.json());
            })
            .catch((err) => reject(err));
    });
}

