import { Row, Col, Form, Button, Dropdown, DropdownButton } from 'react-bootstrap'


const EditCourtForm = () => {
    return (
        <Form>
            <Form.Group >
                <Form.Label className="myLabel">Username</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="Enter username" />
            </Form.Group>
            <Form.Group>
                <Form.Label className="myLabel">Password</Form.Label>
                <Form.Control className="myInputField" type="password" placeholder="Password" />
            </Form.Group>
            <Form.Label className="myLabel">Date</Form.Label>
            <Form.Control type="date" name='date_of_birth' />
            <br></br>
            <Form.Label className="myLabel">Time Slot</Form.Label>
            <DropdownButton className="myDropDown" id="dropdown-basic-button" title="Dropdown button">
                <Dropdown.Item className="myDropDownText" href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item className="myDropDownText" href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item className="myDropDownText" href="#/action-3">Something else</Dropdown.Item>
            </DropdownButton>
            <br></br>
            <br></br>
            <Row>
                <Col className="myFormControls" lg={5}>
                    <Button className="myButtonForm" variant="primary" type="submit">
                        Submit
                    </Button>
                    <Button className="myButtonForm" variant="primary" type="submit">
                        Delete Court
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}

export default EditCourtForm;