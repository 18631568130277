import { Jumbotron, Container } from 'react-bootstrap'
import TopBar from '../Components/TopBar';

const SettingsView = () => {
    return (
        <div className="mySettingsView">
            <TopBar>    </TopBar>
            <Container>
                <Jumbotron className="mySettingsJumbotron">
                    <div className="settingsContent">
                        <div className="myAvatarCircle">
                        </div>
                        <h1 className="settingsName" >Ahmed Mohamed</h1>
                        <h3 className="settingsEmail">Ahmedhassan@jpadel.com</h3>
                    </div>
                </Jumbotron>
            </Container>
        </div>
    );
}

export default SettingsView;