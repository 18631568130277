import AddTournamentForm from "../Components/AddTournamentForm"
import TopBar from "../Components/TopBar"

const AddTournaments = () => {
    return (
        <div className="addTournaments">
            <TopBar></TopBar>
            <AddTournamentForm></AddTournamentForm>
        </div>
    );
}

export default AddTournaments;