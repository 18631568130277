import { Row, Col, Form, Button } from 'react-bootstrap'
import React, { useState } from "react";
import Collapsible from 'react-collapsible';
import { BsCaretDownFill } from "react-icons/bs";
import '@szhsin/react-menu/dist/index.css';
import {addLocationAction} from '../Views/Locations/locationsSlice'
import { useDispatch } from "react-redux";




const AddCourtForm = () => {
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(false);


    const handleChange = () => {
        // Change state to the opposite (to ture) when checkbox changes
        setChecked(!checked);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let courtNamesArr = e.target.courtNames.value.split(',');
        let courtPricesArr = e.target.courtPrices.value.split(',');
        for(var i=0; i<courtNamesArr.length; i++){
            courtNamesArr[i] = courtNamesArr[i].trim();
            courtPricesArr[i] = courtPricesArr[i].trim();
            courtPricesArr[i] = parseInt(courtPricesArr[i]);
        }
        

        console.log('Trying to access checked values: ', e.target);
        //debugger;
        let data = {
                locationName: e.target.locationName.value,
                closingHours: {
                    "1": parseInt(e.target.Mon.value),
                    "2": parseInt(e.target.Tue.value),
                    "3": parseInt(e.target.Wed.value),
                    "4": parseInt(e.target.Thu.value),
                    "5": parseInt(e.target.Fri.value),
                    "6": parseInt(e.target.Sat.value),
                    "7": parseInt(e.target.Sun.value)
                },
                openingTime: parseInt(e.target.openingTime.value),
                requiresUserId: checked,
                courtNames: courtNamesArr,
                courtPrices: courtPricesArr,
                locationAddress: e.target.locationAddress.value,
                locationFeatures: e.target.locationFeatures.value,
                locationImage: e.target.locationImage.value,
                locationImageHorizontal: e.target.locationImageHorizontal.value,
                locationInformation: e.target.locationInformation.value,
                locationLat: e.target.locationLat.value,
                locationLng: e.target.locationLng.value,
                locationOrder: parseInt(e.target.locationOrder.value),
                locationOriginalMobile: e.target.locationOriginalMobile.value,
                maxBookingsPerDay: parseInt(e.target.maxBookingsPerDay.value),
                numberOfCourts: parseInt(e.target.numberOfCourts.value),
                numberOfHours: parseInt(e.target.numberOfHours.value),
                pricePerCourt: parseInt(e.target.pricePerCourt.value),
                priceRange: e.target.priceRange.value,
                requiresIdStart: e.target.idStartTime? parseInt(e.target.idStartTime.value):null,
                requiresIdEnd: e.target.idEndTime ? parseInt(e.target.idEndTime.value):null,
                idScreenDisclaimer: e.target.idScreenDisclaimer ? e.target.idScreenDisclaimer.value:null,
                idScreenLongText: e.target.idScreenLongText ? e.target.idScreenLongText.value:null,
                idScreenText: e.target.idScreenText ? e.target.idScreenText.value:null,            
        };
        debugger;
        dispatch(addLocationAction(data));
    
        console.log('Dispatch succeeded');
    };

    // const HandleDropDown = (e) => {
    //     document.getElementById('dropdown', '.keep-open').onclick = function (e) {
    //         e.stopPropagation();
    //     }
    //     console.log('Drop down');
    // }


    return (


        <Form  onSubmit={handleSubmit}>
            <Form.Group controlId="locationName">
                <Form.Label className="myLabel">Location Name</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="Location Name" />
            </Form.Group>
            <Form.Group controlId="locationImage">
                <Form.Label className="myLabel">Image</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="Image URL" />
            </Form.Group >

            <Form.Group controlId="locationImageHorizontal">
                <Form.Label className="myLabel">Location Image Horizontal</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="Horizontal image url" />
            </Form.Group >

            <Form.Group controlId="openingTime">
                <Form.Label className="myLabel">Opening Time</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="Opening Time" />
            </Form.Group>


            <Form.Group controlId="closingHours" size="lg">
                <Collapsible trigger={<div><Form.Label>Closing Hours <BsCaretDownFill> </BsCaretDownFill> </Form.Label></div>}>
                    <Form.Group as={Row} controlId="Sat" size="sm">
                        <Form.Label column sm="2">
                            Saturday
                        </Form.Label>
                        <Col>
                            <Form.Control
                                type="text"
                                defaultValue="0"
                                onChange={(val) => {


                                }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="Sun" size="sm">
                        <Form.Label column sm="2">
                            Sunday
                        </Form.Label>

                        <Col>
                            <Form.Control
                                type="text"
                                defaultValue="0"

                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="Mon" size="sm">
                        <Form.Label column sm="2">
                            Monday
                        </Form.Label>

                        <Col>
                            <Form.Control
                                type="text"
                                defaultValue="0"


                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="Tue" size="sm">
                        <Form.Label column sm="2">
                            Tuesday
                        </Form.Label>

                        <Col>
                            <Form.Control
                                type="text"
                                defaultValue="0"

                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="Wed" size="sm">
                        <Form.Label column sm="2">
                            Wednesday
                        </Form.Label>

                        <Col>
                            <Form.Control
                                type="text"
                                defaultValue="0"


                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="Thu" size="sm">
                        <Form.Label column sm="2">
                            Thursday
                        </Form.Label>

                        <Col>
                            <Form.Control
                                type="text"
                                defaultValue="0"


                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="Fri" size="sm">
                        <Form.Label column sm="2">
                            Friday
                        </Form.Label>

                        <Col>
                            <Form.Control
                                type="text"
                                defaultValue="0"
                            />
                        </Col>
                    </Form.Group>
                </Collapsible>
            </Form.Group>
            
            <Form.Group controlId="pricePerCourt">
                <Form.Label className="myLabel">Price for all courts</Form.Label>
                <Form.Control className="myInputField" type="number" placeholder="Price for all court" />
            </Form.Group>


            <div className="conditional-checkbox">
                <label
                    htmlFor="inputVacationPercentage"
                    className="switch switch-default"
                >
                    Does this place require id?                </label>
                <input
                    id="inputVacationPercentage"
                    type="checkbox"
                    checked={checked}
                    onChange={handleChange}
                />

                { /* Inline conditional if checked state is `true` will show the div, otherwise, it won't */}

                {checked && (
                    <div
                        number-input
                        id="qualificationYearDropdown"
                        class="form-group col-lg-2 col-md-4 col-sm-8"
                    >
                        <Form.Group controlId="idStartTime">
                            <Form.Label className="myLabel" >ID start Time</Form.Label>
                            <Form.Control className="myInputField" type="text" placeholder="ID Start Time" />
                        </Form.Group>
                        <Form.Group controlId="idEndTime">
                            <Form.Label className="myLabel">ID close Time</Form.Label>
                            <Form.Control className="myInputField" type="text" placeholder="ID Close Time" />
                        </Form.Group>
                        <Form.Group controlId="idScreenDisclaimer">
                        <Form.Label className="myLabel">idScreenDisclaimer</Form.Label>
                        <Form.Control className="myInputField" type="text" placeholder="Screen Disclaimer" />
                        </Form.Group>
                        <Form.Group controlId="idScreenLongText">
                            <Form.Label className="myLabel">idScreenLongText</Form.Label>
                            <Form.Control className="myInputField" type="text" placeholder="idScreenLongText" />
                        </Form.Group>
                        <Form.Group controlId="idScreenText">
                            <Form.Label className="myLabel">idScreenText</Form.Label>
                            <Form.Control className="myInputField" type="text" placeholder="idScreenText" />
                        </Form.Group>
                    </div>
                )}
            </div>
            <Form.Group controlId="locationAddress">
                <Form.Label className="myLabel">location Address</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="locationAddress" />
            </Form.Group>

            <Form.Group controlId="locationInformation">
                <Form.Label className="myLabel">location Information</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="locationInformation" />
            </Form.Group>

            <Form.Group controlId="locationFeatures">
                <Form.Label className="myLabel">Location Features</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="Location Features" />
            </Form.Group>        

             <Form.Group controlId="locationOriginalMobile">
                <Form.Label className="myLabel">Location Original Mobile</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="locationOriginalMobile" />
            </Form.Group>

            <Form.Group controlId="locationLat">
                <Form.Label className="myLabel">Location Latidude</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="locationLat" />
            </Form.Group>

            <Form.Group controlId="locationLng">
                <Form.Label className="myLabel">location Longitude</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="locationLng" />
            </Form.Group>

            <Form.Group controlId="locationOrder">
                <Form.Label className="myLabel">Location order</Form.Label>
                <Form.Control className="myInputField" type="number" placeholder="Location Order" />
            </Form.Group>

             <Form.Group controlId="maxBookingsPerDay">
                <Form.Label className="myLabel">Max Bookings Per Day</Form.Label>
                <Form.Control className="myInputField" type="number" placeholder="maxBookingsPerDay" />
            </Form.Group>

             <Form.Group controlId="numberOfCourts">
                <Form.Label className="myLabel">Number Of Courts</Form.Label>
                <Form.Control className="myInputField" type="number" placeholder="numberOfCourts" />
            </Form.Group>

            <Form.Group controlId="numberOfHours">
                <Form.Label className="myLabel">Number Of Operating Hours</Form.Label>
                <Form.Control className="myInputField" type="number" placeholder="Number of operating hours" />
            </Form.Group>

             <Form.Group controlId="priceRange">
                <Form.Label className="myLabel">Price Range</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="priceRange" />
            </Form.Group>

             <Form.Group controlId="courtNames">
                <Form.Label className="myLabel">Court Names</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="Enter court names seperated by a comma ','" />
            </Form.Group>

             <Form.Group controlId="courtPrices">
                <Form.Label className="myLabel">Court Prices</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="Enter court prices seperated by a comma ',' " />
            </Form.Group>

            <Form.Group className="mb-3" controlId="isVisible">
            <Form.Check type="checkbox" label="isVisible ?" />
            </Form.Group>        



            <Row>
                <Col lg={6} >
                    <Button className="myButtonFormAdd" variant="primary" type="submit">
                        Submit
                    </Button>
                </Col>
            </Row>
        </Form>

    );
}

export default AddCourtForm;