import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sendNotificationToAllUsers } from "./notificationsAPI";

const initialState = {
    status: "idle",
};

export const sendNotification = createAsyncThunk(
    "notifications/sendNotification",
    async(data) => {
        const response = await sendNotificationToAllUsers(data);
        return response;
    }
);

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(sendNotification.pending, (state) => {
                state.status = "loading";
            })
            .addCase(sendNotification.fulfilled, (state, action) => {
                state.status = "succeeded";
            })
            .addCase(sendNotification.rejected, (state, action) => {
                state.status = "failed";
            });
    }
});

export const {} = notificationsSlice.actions;

export default notificationsSlice.reducer;
