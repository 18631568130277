import BookingsTable from "..//..//Components/BookingsTable"

const Bookings = () => {
    return (
        <div className="myBookings" >
            <BookingsTable > </BookingsTable>
        </div>
    );
}

export default Bookings;