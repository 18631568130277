import { Row, Col, Form, Button } from 'react-bootstrap'
import React, { useState } from "react";
import '@szhsin/react-menu/dist/index.css';
import { useLocation} from 'react-router-dom';
import {
    editTournaments,
    retrieveCertainTournament,
    modify,
    selectedTournament,
} from '../Views/Tournaments/tournamentsSlice'
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
// import storage from '../firebase';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import { render } from '@testing-library/react';


const EditTournamentForm = () => {
    const tournaments = useSelector(selectedTournament);
    const retrievalStatus = useSelector((state) => state.tournaments.retrievalStatus);
    const editStatus = useSelector((state) => state.tournaments.editStatus);
    const location = useLocation()
    const dispatch = useDispatch();
    let tournamentId = location.state.id;
    console.log('This is tournament id: ', tournamentId);

   var urlPDF = '';
   var urlImage = '';
   var urlPDFFound = false;
   var urlImageFound = false;
   var s ='idle';

    useEffect(() => {
        dispatch(retrieveCertainTournament({id:tournamentId })); 
      },[]);
    debugger;
    
      const [file , setFile] = useState('');
      const [image , setImage] = useState('');

    const storage = getStorage();


  const uploadPDF = ()=>{
    if(file == null)
      return;
    
      const metadata = {
        contentType: 'application/pdf'
    };
    
    const pdfRef = ref(storage, `/tournaments/${file.name}`);
    const uploadTask = uploadBytesResumable(pdfRef, file, metadata);

    uploadTask.on('state_changed',
        (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case 'paused':
                    console.log('Upload is paused');
                    break;
                case 'running':
                    console.log('Upload is running');
                    break;
            }
        }, 
        (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    break;
                case 'storage/canceled':
                    // User canceled the upload
                    break;
                case 'storage/unknown':
                    // Unknown error occurred, inspect error.serverResponse
                    break;
            }
        }, 
        () => {
            // Upload completed successfully, now we can get the download URL
            console.log('Upload is completed');
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                urlPDFFound=true;
                urlPDF=downloadURL.toString();
            });
        }
    );
        
  }

  const uploadImage = ()=>{
    if(image == null)
      return;

      const metadata = {
        contentType: 'image/jpeg'
    };

      const imagesRef = ref(storage, `/tournaments/${image.name}`);
    const uploadTask = uploadBytesResumable(imagesRef, image, metadata);

    uploadTask.on('state_changed',
        (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case 'paused':
                    console.log('Upload is paused');
                    break;
                case 'running':
                    console.log('Upload is running');
                    break;
            }
        }, 
        (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    break;
                case 'storage/canceled':
                    // User canceled the upload
                    break;
                case 'storage/unknown':
                    // Unknown error occurred, inspect error.serverResponse
                    break;
            }
        }, 
        () => {
            // Upload completed successfully, now we can get the download URL
            console.log('Upload is completed');
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                urlImageFound=true;
                urlImage=downloadURL.toString();
            });
        }
    );      
        
  }

    const handleSubmit = (e) => {
        e.preventDefault();
         let tournamentTagsArr = e.target.tournamentTags.value.split(',');
         for(var i=0; i<tournamentTagsArr.length; i++){
            tournamentTagsArr[i] = tournamentTagsArr[i].trim();
         }
        

        console.log('Trying to access checked values: ', e.target);
        debugger;
        let data = {
                priceForTwoLevels: parseInt(e.target.priceForTwoLevels.value),
                pricePerPlayer: parseInt(e.target.pricePerPlayer.value),
                prizeMoney: e.target.prizeMoney.value,
                registrationDeadline: e.target.registrationDeadline.value,
                requiresQrCode: (e.target.requiresQrCode.checked) ? true : false,
                tournamentAddress: e.target.tournamentAddress.value,
                tournamentDateRange: e.target.tournamentDateRange.value,
                tournamentDetails: e.target.tournamentDetails.value,
                tournamentEndTime: e.target.tournamentEndTime.value,
                tournamentDrawUrl: urlPDFFound?urlPDF:e.target.tournamentDrawUrl.value,
                tournamentImage: urlImageFound?urlImage:e.target.tournamentImage.value,
                tournamentInformation: e.target.tournamentInformation.value,
                tournamentLevel: e.target.tournamentLevel.value,
                tournamentName: e.target.tournamentName.value,
                tournamentTags: tournamentTagsArr,

        };
        dispatch(editTournaments({id:tournamentId, data:data }));
        dispatch(retrieveCertainTournament({id:tournamentId }));

        console.log('Dispatch succeeded');
    };

    return (
        <Form  onSubmit={handleSubmit}>
            <Form.Group controlId="tournamentName">
                <Form.Label className="myLabel">Tournament Name</Form.Label>
                <Form.Control className="myInputField" type="text" 
                defaultValue={tournaments.tournamentName} 
                onChange={(val) => {let u = Object.assign({}, tournaments, {
                                                                "tournamentName": val.target.value,});
                                                                dispatch(modify(u));}}
                                                                />
            </Form.Group>

            <Form.Group controlId="tournamentImage">
                <Form.Label className="myLabel">Tournament Image</Form.Label>
                <Form.Control className="myInputField" type="text" 
                defaultValue={urlImageFound?urlImage:tournaments.tournamentImage} 
                onChange={(val) => {let u = Object.assign({}, tournaments, {
                                                                "tournamentImage": urlImageFound?urlImage:val.target.value,});
                                                                dispatch(modify(u));}}/>

            </Form.Group>

            <input type="file"   onChange={(e)=>{setImage(e.target.files[0])}}/>
            <Button className="addCourtButton" type="button" onClick={uploadImage} variant="primary">Upload</Button>
            
            <Form.Group controlId="tournamentDrawUrl">
                <Form.Label className="myLabel">Tournament Draw Url</Form.Label>
                <Form.Control className="myInputField" type="text" 
                defaultValue={urlPDFFound?urlPDF:tournaments.tournamentDrawUrl} 
                
                onChange={(val) => {let u = Object.assign({}, tournaments, {
                                                                "tournamentDrawUrl": urlPDFFound?urlPDF:val.target.value,});
                                                                dispatch(modify(u));}}/>
            </Form.Group>
            
            <input type="file"   onChange={(e)=>{setFile(e.target.files[0])}}/>
            <Button className="addCourtButton" type="button" onClick={uploadPDF} variant="primary">Upload</Button>
     

            <Form.Group controlId="tournamentAddress">
                <Form.Label className="myLabel">Tournament Address</Form.Label>
                <Form.Control className="myInputField" type="text"  
                defaultValue={tournaments.tournamentAddress} 
                onChange={(val) => {let u = Object.assign({}, tournaments, {
                                                                "tournamentAddress": val.target.value,});
                                                                dispatch(modify(u));}}/>
            </Form.Group>

            <Form.Group controlId="tournamentInformation">
                <Form.Label className="myLabel">Tournament Information</Form.Label>
                <Form.Control className="myInputField" type="text"  
                defaultValue={tournaments.tournamentInformation} 
                onChange={(val) => {let u = Object.assign({}, tournaments, {
                                                                "tournamentInformation": val.target.value,});
                                                                dispatch(modify(u));}}/>
            </Form.Group>

            <Form.Group controlId="tournamentDetails">
                <Form.Label className="myLabel">Tournament Details</Form.Label>
                <Form.Control className="myInputField" type="text"  
                defaultValue={tournaments.tournamentDetails} 
                onChange={(val) => {let u = Object.assign({}, tournaments, {
                                                                "tournamentDetails": val.target.value,});
                                                                dispatch(modify(u));}}/>
            </Form.Group>

            <Form.Group controlId="priceForTwoLevels">
                <Form.Label className="myLabel">Price For Two Levels</Form.Label>
                <Form.Control className="myInputField" type="number" 
                 defaultValue={tournaments.priceForTwoLevels} 
                onChange={(val) => {let u = Object.assign({}, tournaments, {
                                                                "priceForTwoLevels": val.target.value,});
                                                                dispatch(modify(u));}}/>
            </Form.Group>

            <Form.Group controlId="pricePerPlayer">
                <Form.Label className="myLabel">Price Per Player</Form.Label>
                <Form.Control className="myInputField" type="number"  
                defaultValue={tournaments.pricePerPlayer} 
                onChange={(val) => {let u = Object.assign({}, tournaments, {
                                                                "pricePerPlayer": val.target.value,});
                                                                dispatch(modify(u));}}/>
            </Form.Group>

            <Form.Group controlId="prizeMoney">
                <Form.Label className="myLabel">Prize Money</Form.Label>
                <Form.Control className="myInputField" type="text" 
                defaultValue={tournaments.prizeMoney} 
                onChange={(val) => {let u = Object.assign({}, tournaments, {
                                                                "prizeMoney": val.target.value,});
                                                                dispatch(modify(u));}}/>
            </Form.Group>        

             <Form.Group controlId="registrationDeadline">
                <Form.Label className="myLabel">Registration Deadline</Form.Label>
                <Form.Control className="myInputField" type="text" 
                defaultValue={retrievalStatus==="succeeded"?(new Date(tournaments.registrationDeadline._seconds*1000)).toLocaleString():tournaments.registrationDeadline}
                onChange={(val) => {let u = Object.assign({}, tournaments, {
                                                                "registrationDeadline": val.target.value,});
                                                                dispatch(modify(u));}}/>
            </Form.Group>

            <Form.Group controlId="tournamentEndTime">
                <Form.Label className="myLabel">Tournament EndTime</Form.Label>
                <Form.Control className="myInputField" type="text" 
                defaultValue={retrievalStatus==="succeeded"?(new Date(tournaments.tournamentEndTime._seconds*1000)).toLocaleString():tournaments.tournamentEndTime} 
                onChange={(val) => {let u = Object.assign({}, tournaments, {
                                                                "tournamentEndTime": val.target.value,});
                                                                dispatch(modify(u));}}/>
            </Form.Group>

            <Form.Group controlId="tournamentDateRange">
                <Form.Label className="myLabel">Tournament Date Range</Form.Label>
                <Form.Control className="myInputField" type="text" 
                 defaultValue={tournaments.tournamentDateRange} 
                onChange={(val) => {let u = Object.assign({}, tournaments, {
                                                                "tournamentDateRange": val.target.value,});
                                                                dispatch(modify(u));}}/>
            </Form.Group>

             <Form.Group controlId="tournamentLevel">
                <Form.Label className="myLabel">Tournament Level</Form.Label>
                <Form.Control className="myInputField" type="text" 
                 defaultValue={tournaments.tournamentLevel} 
                onChange={(val) => {let u = Object.assign({}, tournaments, {
                                                                "tournamentLevel": val.target.value,});
                                                                dispatch(modify(u));}}/>
            </Form.Group>

             <Form.Group controlId="tournamentTags">
                <Form.Label className="myLabel">Tournament Tags</Form.Label>
                <Form.Control className="myInputField" type="text" 
                 defaultValue={tournaments.tournamentTags} 
                onChange={(val) => {let u = Object.assign({}, tournaments, {
                                                                "tournamentTags": val.target.value,});
                                                                dispatch(modify(u));}}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="requiresQrCode">
            <Form.Check type="checkbox" label="Requires Qr Code?" 
            defaultChecked={tournaments.requiresQrCode} 
            />
            </Form.Group>        

            <Row>
                <Col lg={6} >
                    <Button className="myButtonFormAdd" variant="primary" type="submit">
                     Submit   
                    </Button>
                </Col>
            </Row>
        </Form>

);
                
    }

 
export default EditTournamentForm;