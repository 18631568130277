import { configureStore } from '@reduxjs/toolkit';
import locationsSlice from '../Views/Locations/locationsSlice';
import usersSlice from '../Views/Users/usersSlice';
import academiesSlice from '../Views/Academies/academiesSlice';
import bookingsSlice from '../Views/Bookings/bookingsSlice';
import tournamentsSlice from '../Views/Tournaments/tournamentsSlice';
import adminReportSlice from '../Views/AdminReport/adminReportSlice';
import locationsReportsSlice from '../Views/LocationsReport/locationsReportSlice';
import overdueSlice from '../Views/Overdue/overdueSlice';
import authSlice from '../authSlice';
import tournamentsInvitationsSlice from '../Views/Tournaments/tournamentsInvitationsSlice';
import ratingsReportsSlice from '../Views/RatingsReport/ratingsReportSlice';
import inventoryReportSlice from '../Views/InventoryReport/inventoryReportSlice';
import courtAdminsSlice from '../Views/CourtAdmins/courtAdminsSlice';
import challengesSlice from '../Views/Challenges/challengesSlice';
import notificationsSlice from '../Views/Notifications/notificationsSlice';
export const store = configureStore({
    reducer: {
        users: usersSlice,
        locations: locationsSlice,
        academies: academiesSlice,
        bookings: bookingsSlice,
        tournaments: tournamentsSlice,
        adminReports: adminReportSlice,
        locationsReports: locationsReportsSlice,
        overdue: overdueSlice,
        auth: authSlice,
        tournamentsInvitations: tournamentsInvitationsSlice,
        ratingsReports: ratingsReportsSlice,
        inventoryReports: inventoryReportSlice,
        courtAdmins: courtAdminsSlice,
        challenges: challengesSlice,
        notifications: notificationsSlice,
    },
});