import { InputGroup, FormControl, Container } from 'react-bootstrap'
import { BiSearchAlt } from "react-icons/bi";
import "./UsersTable";

const SearchBar = (props) => {
    // var test = "";

    return (
        <Container>
            <InputGroup className="mb-3">
                <InputGroup.Prepend >
                    <InputGroup.Text className="mySearchBar" id="basic-addon1" onClick={props.handleClick}><BiSearchAlt className="mySearch"
                    ></BiSearchAlt></InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl className="mySearchBarWidget"
                    placeholder={props.placeholder}
                    aria-label="Search"
                    onChange={props.handleSearch}
                    // onChange={(e)=>{test = e.target.value;}}
                    onKeyPress={props.keyPress}
                />
            </InputGroup>
        </Container>

    );
}

export default SearchBar;