export function getAllLocations() {
    return new Promise((resolve, reject) => {
        fetch("https://www.jpadel-cms.com/api/locations/all", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "authorization": window.localStorage.getItem('token'),
                },
            })
            .then((res) => {
                resolve(res.json());
            })
            .catch((err) => reject(err));
    });
}


export function modifyLocation(data) {
    return new Promise((resolve, reject) => {
        fetch("https://www.jpadel-cms.com/api/locations", {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "authorization": window.localStorage.getItem('token'),
                },
                body: JSON.stringify(data),
            })
            .then((res) => {
                resolve(res.json());
            })
            .catch((err) => reject(err));
    });
  }


  export function addLocation(data) {
    return new Promise((resolve, reject) => {
      debugger;
      fetch("https://www.jpadel-cms.com/api/locations", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          resolve(res.json());
        })
        .catch((err) => reject(err));
    });
  }
