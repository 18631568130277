import '../addChallenges.css'
import {WalletTopUp, WalletTransfer} from "./challenges/wallet"

const WalletChallenges = (props) => {

    return (
        <div>
            <div className="challenges-grid">
                <WalletTopUp addToList={props.addToList} />
                <WalletTransfer addToList={props.addToList} />
            </div>
        </div>
    );
}

export default WalletChallenges;