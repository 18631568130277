import React, { useState } from "react";
import { AiFillHome } from "react-icons/ai";
import { BsPersonFill, BsFillPersonLinesFill } from "react-icons/bs";
import { BiChevronsRight } from "react-icons/bi";
import { AiOutlineAlignLeft } from "react-icons/ai";
import { AiFillCalendar } from "react-icons/ai";
import { RiErrorWarningFill, RiShutDownLine, RiBuilding4Fill, RiAdminFill, RiNotificationFill } from "react-icons/ri";
// import { IoIosTennisball } from "react-icons/io";
// import { FaUniversity } from "react-icons/fa";
//import { SiGoogleanalytics } from "react-icons/si";
// import { BsFillBellFill } from "react-icons/bs";
import { FaTrophy } from "react-icons/fa";
import { IoLocationSharp, IoStar, IoCart, IoPodium } from "react-icons/io5";



import myLogo from '../assets/logo.svg';


const SideBar = (props) => {

    const myHome = props.myHome;
    const myUsers = props.myUsers;
    const myBookings = props.myBookings;
    const myAddBooking = props.myAddBooking;
    const myAddUser = props.myAddUser;
    const myCourts = props.myCourts;
    const myEditCourt = props.myEditCourt;
    const myAddCourt = props.myAddCourt;
    const myCoaches = props.myCoaches;
    const myAddCoach = props.myAddCoach;
    const myEditCoach = props.myEditCoach;
    const myTournaments = props.myTournaments
    const myAddTournaments = props.myAddTournaments;
    const myEditTournaments = props.myEditTournaments;
    const myAcademies = props.myAcademies;
    const myOverdue = props.myOverdue;
    const myNotifications = props.myNotifications;
    const mySettings = props.mySettings;
    const myLocationsReport = props.myLocationsReport;
    const myAdminReports = props.myAdminReports
    const myTournamentsTable = props.myTournamentsTable;
    const myRatingsReport = props.myRatingsReport;
    const myInventoryReport = props.myInventoryReport;
    const myCourtAdmins = props.myCourtAdmins;
    const myChallenges = props.myChallenges;
    const [toggle, setToggle] = useState('');
    const [hide, setHide] = useState('block');

    const handleState = () => {
        if (toggle === '') {
            setToggle('active');
            setHide('none');
        }
        else if (toggle === 'active') {
            setToggle('');
            setHide('block');
        }

    }

    return (
        <div className="wrapper">
            <nav className={toggle} id="sidebar">
                <div className="sidebar-header">
                    <img className="myLogoNav" src={myLogo} alt="JPadeLogo" />
                    <span className="myNavTitle">JPadel</span>
                </div>
                <ul className="list-unstyled components">
                    <li className="fullTag">
                        <a href="/"><AiFillHome className="myIcon"> </AiFillHome>Home<BiChevronsRight id="myArrow"></BiChevronsRight>
                        </a>
                    </li>
                    <li className="fullTag">
                        <a href="/users"><BsPersonFill className="myIcon"></BsPersonFill>Users<BiChevronsRight id="myArrow"></BiChevronsRight>
                        </a>
                    </li>
                    <li className="fullTag">
                        <a href="/court-admins"><BsFillPersonLinesFill className="myIcon"></BsFillPersonLinesFill>Court Admins<BiChevronsRight id="myArrow"></BiChevronsRight>
                        </a>
                    </li>
                    <li className="fullTag">
                        <a href="/courts"><RiBuilding4Fill className="myIcon"></RiBuilding4Fill>Courts<BiChevronsRight id="myArrow"></BiChevronsRight>
                        </a>
                    </li>
                    <li className="fullTag">
                        <a href="/overdue"> <RiErrorWarningFill className="myIcon"></RiErrorWarningFill>Overdue<BiChevronsRight id="myArrow"></BiChevronsRight>
                        </a>
                    </li>
                    {/*<li className="fullTag">
                        <a href="/coaches"><IoIosTennisball className="myIcon"></IoIosTennisball>Coaches<BiChevronsRight id="myArrow"></BiChevronsRight>
                        </a>
                    </li>*/}

                    {/*<li className="fullTag">
                        <a href="/analytics"><SiGoogleanalytics className="myIcon"></SiGoogleanalytics>Analytics<BiChevronsRight id="myArrow"></BiChevronsRight>
                        </a> 
                   </li>*/}
                    <li className="fullTag">
                        <a href="/bookings"><AiFillCalendar className="myIcon"></AiFillCalendar>Bookings<BiChevronsRight id="myArrow"></BiChevronsRight>
                        </a>
                    </li>
                    {/*<li className="fullTag">
                        <a href="/academies"> <FaUniversity className="myIcon"></FaUniversity>Academies<BiChevronsRight id="myArrow"></BiChevronsRight>
                        </a>
                    </li>*/}
                    {/* <li className="fullTag">
                        <a href="/notifications"><BsFillBellFill className="myIcon"></BsFillBellFill>Notifications<BiChevronsRight id="myArrow"></BiChevronsRight>
                        </a>
                    </li> */}
                    <li className="fullTag">
                        <a href="/tournaments"><FaTrophy className="myIcon"></FaTrophy>Tournaments<BiChevronsRight id="myArrow"></BiChevronsRight>
                        </a>
                    </li>
                    <li className="fullTag">
                        <a href="/challenges"><IoPodium className="myIcon"></IoPodium>Challenges<BiChevronsRight id="myArrow"></BiChevronsRight>
                        </a>
                    </li>
                    <li className="fullTag">
                        <a href="/notifications"><RiNotificationFill className="myIcon"></RiNotificationFill>Notifications<BiChevronsRight id="myArrow"></BiChevronsRight>
                        </a>
                    </li>
                    <li className="fullTag">
                        <a href="/admin-report"><RiAdminFill className="myIcon"></RiAdminFill>Admin Reports<BiChevronsRight id="myArrow"></BiChevronsRight>
                        </a>
                    </li>
                    <li className="fullTag">
                        <a href="/locations-report"><IoLocationSharp className="myIcon"></IoLocationSharp>Locations Reports<BiChevronsRight id="myArrow"></BiChevronsRight>
                        </a>
                    </li>
                    <li className="fullTag">
                        <a href="/rating-report"><IoStar className="myIcon"></IoStar>Ratings Reports<BiChevronsRight id="myArrow"></BiChevronsRight>
                        </a>
                    </li>
                    <li className="fullTag">
                        <a href="/inventory-report"><IoCart className="myIcon"></IoCart>Inventory Reports<BiChevronsRight id="myArrow"></BiChevronsRight>
                        </a>
                    </li>
                </ul>
                <ul className="list-unstyled CTAs">
                    <li>
                        <a href="/logout" className="article"><RiShutDownLine className="myIconBot"></RiShutDownLine>Logout</a>
                    </li>
                </ul>
            </nav>

            <div id="content">
                <nav className="myNavbar navbar  ">
                    <div className="container-fluid">

                        <button onClick={handleState} type="button" id="sidebarCollapse" className="myToggler btn btn-info">
                            <AiOutlineAlignLeft></AiOutlineAlignLeft>
                            <span></span>
                        </button>
                    </div>
                </nav>
                <div className={hide} id="myMainContent">
                    <div>
                        {myHome}
                        {myUsers}
                        {myBookings}
                        {myAddBooking}
                        {myAddUser}
                        {myCourts}
                        {myAddCourt}
                        {myEditCourt}
                        {myCoaches}
                        {myAddCoach}
                        {myEditCoach}
                        {myTournaments}
                        {myAddTournaments}
                        {myEditTournaments}
                        {myAcademies}
                        {myOverdue}
                        {mySettings}
                        {myNotifications}
                        {myLocationsReport}
                        {myAdminReports}
                        {myTournamentsTable}
                        {myRatingsReport}
                        {myInventoryReport}
                        {myCourtAdmins}
                        {myChallenges}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SideBar;
