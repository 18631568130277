export async function getNumberOfUsers () {
    let response = await fetch("https://www.jpadel-cms.com/api/users/number", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "authorization": window.localStorage.getItem('token'),
        },
    });
    let data = await response.json();
    return data;
}

export async function getClicks () {
    let response = await fetch("https://www.jpadel-cms.com/api/users/clicks", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "authorization": window.localStorage.getItem('token'),
        },
    });
    let data = await response.json();
    return data;
}