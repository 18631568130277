import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllTournaments, modifyTournament, addTournament, getAllTournamentInvitations, getCertainTournament, getActiveTournaments} from "./tournamentsAPI"

const initialState = {
    value: [],
    status: "idle",
    editStatus: "idle",
    retrievalStatus:"idle",
    id: '',
    selectedTournament: "",
};
export const retrieveTournaments = createAsyncThunk("tournaments/getAllTournaments", async() => {
    const response = await getAllTournaments();
    return response;
});

export const retrieveTournamentInvitations = createAsyncThunk("tournament_invitations/getAllTournamentInvitations", async(data) => {
    const response = await getAllTournamentInvitations(data);
    return response;
});

export const retrieveCertainTournament = createAsyncThunk("tournaments/getCertainTournament", async(data) => {
    const response = await getCertainTournament(data);
    return response;
});

export const retrieveActiveTournamets = createAsyncThunk("tournaments/getActiveTournaments", async(data) => {
    const response = await getActiveTournaments(data);
    return response;
});

export const editTournaments = createAsyncThunk(
    "tournaments/modifyTournament",
    async(data) => {
        const response = await modifyTournament(data);
        return response;
    }
);

export const addTournamentAction = createAsyncThunk(
    "tournaments/addTournament",
    async(data) => {
        const response = await addTournament(data);
        return response;
    }
);


export const tournamentsSlice = createSlice({
    name: "tournaments",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        modify: (state, action) => {
            var newData = state.value.map((tournament) => {
                if (tournament.id === action.payload.id)
                    return Object.assign({}, tournament, action.payload);
                return tournament;
            });
            state.value = newData;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: {
        [retrieveTournaments.pending]: (state, action) => {
            state.status = "loading";
        },
        [retrieveTournaments.fulfilled]: (state, action) => {
            state.status = "succeeded";
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                }
            } else {
                state.value = action.payload;
            }
        },
        [retrieveTournaments.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        },
        [retrieveCertainTournament.pending]: (state, action) => {
            state.status = "loading";
            state.retrievalStatus = "loading";
        },
        [retrieveCertainTournament.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.retrievalStatus = "succeeded";
            state.selectedTournament = action.payload;
        },
        [retrieveCertainTournament.rejected]: (state, action) => {
            state.status = "failed";
            state.retrievalStatus = "failed";
            state.error = action.error.message;
        },
        [editTournaments.pending]: (state, action) => {
            state.editStatus = "loading";
        },
        [editTournaments.fulfilled]: (state, action) => {
            state.editStatus = "succeeded";
            // Add any fetched posts to the array
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                }
            } else {
                state.value = action.payload;
            }
        },
        [editTournaments.rejected]: (state, action) => {
            state.editStatus = "failed";
            state.error = action.error.message;
        },
        [addTournamentAction.pending]: (state, action) => {
            state.editStatus = "loading";
        },
        [addTournamentAction.fulfilled]: (state, action) => {
            state.editStatus = "succeeded";
            // Add any fetched posts to the array
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                }
            } else {
                state.value = action.payload;
            }
        },
        [addTournamentAction.rejected]: (state, action) => {
            state.editStatus = "failed";
            state.error = action.error.message;
        },
        [retrieveActiveTournamets.pending]: (state, action) => {
            state.status = "loading";
        },
        [retrieveActiveTournamets.fulfilled]: (state, action) => {
            state.status = "succeeded";
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                }
            } else {
                state.value = action.payload;
            }
        },
        [retrieveActiveTournamets.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        }
    },
});



export const { modify } = tournamentsSlice.actions;

export const selectAllTournaments = (state) => state.tournaments.value;
export const selectedTournament = (state) => state.tournaments.selectedTournament;

export default tournamentsSlice.reducer;