import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { retrieveLocations } from '../../../Locations/locationsSlice';
import Select from 'react-select'
import '../../addChallenges.css'

const BookingAtLocation = (props) => {
    const [hours, setHours] = useState(40);
    const [location, setLocation] = useState("");
    const [locationId, setLocationId] = useState("");
    const [name, setName] = useState("");
    const [reward, setReward] = useState(100);
    const [duration, setDuration] = useState(30);
    
    const locationsState = useSelector((state) => state.locations.value);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(retrieveLocations());
    }, [])

    const locationOptions = locationsState.filter((location) => location.visible).map((location) => {
        return {value: location.id, label: location.locationName}
    });

    const getChallenge = () => {
        return {
            id: "Booking at Locations",
            name: name,
            description: "Complete " + hours.toString() + " hours of bookings at " + location,
            points: reward,
            count: hours,
            isNew: true,
            cloudFunction: "ChallengeCompleteXBookings",
            order: 1,
            duration: duration,
            isVisible: true,
            locationId: locationId,
        }
    }

    const choseLocation = (val) => {
        setLocationId(val.value);
        setLocation(val.label);
        setName(val.label);
    }

    return (
        <div className="challenge-card">
            <div className="ch-card title">Bookings at Location</div>
            <div className="ch-card row">Complete <input value={hours} onChange={(ev) => setHours(ev.target.value)} name="hours" className="ch-card number" type="number"/> hours of bookings at <Select onChange={choseLocation} className="ch-card select" options={locationOptions} /></div>
            <div className="ch-card row">Name: <input value={name} onChange={(ev) => setName(ev.target.value)} className="ch-card text"/></div>
            <div className="ch-card row">Reward: <input value={reward} onChange={(ev) => setReward(ev.target.value)} className="ch-card number" type="number"/> EGP</div>
            <div className="ch-card row">Duration: <input value={duration} onChange={(ev) => setDuration(ev.target.value)} className="ch-card number" type="number"/> days</div>
            <button className="ch-card add-button" onClick={() =>{ props.addToList(getChallenge()) }}>Add to list</button>
        </div>
    );
}

export default BookingAtLocation;