import { useEffect } from "react";
import { Table, Container, Button} from "react-bootstrap";
import SearchBar from "../Components/SearchBar";
import TopBar from "../Components/TopBar";
import { useSelector, useDispatch } from "react-redux";
import {
    retrieveBookings,
    selectAllBookings,
    retrieveTodaysBookings,
    retrieveBookingsForUser
} from "../Views/Bookings/bookingsSlice";
import Loading from "./Loading";
import "reactjs-popup/dist/index.css";

const BookingsTable = () => {
  const bookings = useSelector(selectAllBookings);
  const status = useSelector((state) => state.bookings.status);
  const editStatus = useSelector((state) => state.bookings.editStatus);
  const offset = useSelector((state)=> state.bookings.offset);
  const dispatch = useDispatch();

  var search = ""

  useEffect(() => {
    if (status === "idle") {
      dispatch(retrieveBookings(offset));
     // console.log('From use effect: ', bookings)
    }

    // console.log('Bookings from main: ', bookings)
  }, [status, offset, dispatch]);



  console.log('This is status: ', status);
  return (
    <div className="myBookings">
      <TopBar title="All Bookings"></TopBar>
      {/* <Button className="addCourtButton" type="button" variant="primary" onClick={event => window.location.href = '/add-users'}>Add User</Button> */}

      <Container>
        <SearchBar 
          handleSearch={(e)=>{search = e.target.value;}} 
          handleClick={()=>{dispatch(retrieveBookingsForUser(search));}}
          keyPress={(e)=>{e.key === 'Enter' && dispatch(retrieveBookingsForUser(search))}}
          placeholder="Search by Username or User ID">
        </SearchBar>


        <button className="submitButton" onClick={()=>dispatch(retrieveTodaysBookings())} type="next">
                                            Get Today's bookings
                                        </button>
        {status === "loading" || editStatus === "loading" ? (
          <div className="loadingWrapper">
            <Loading
              color="#DD926A"
              type="spin"
              width="20%"
              height="20%"
            ></Loading>
          </div>
        ) : (
          <div>
          <Table bordered hover responsive="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Number(ID)</th>
                <th>Location</th>
                <th>Slot Time</th>
                <th>Slot Price</th>
                <th>Status</th>
                <th>...</th>
              </tr>
            </thead>
            <tbody>
              {bookings.map((booking, index) => {
                  console.log('TESTTTTTTTTTT');
                  console.log(bookings);
                  console.log(booking);
                  var timeSlot = new Date(booking.slotTime._seconds * 1000);
                  var options = {
                    month: 'short',
                    year: 'numeric',
                    weekday: 'long',
                  day:'numeric',
                  hour: 'numeric',
                  hour12:true,
                  minute: 'numeric',
                }
                  //var options = {weekday:'short'}
                  console.log('Inside !!');
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{booking.userThatBookedUsername}</td>
                    <td>{booking.id}</td>
                    <td>{booking.locationName}</td>
                    <td>{timeSlot.toLocaleString('en-GB',options)}</td>
                    <td>{booking.slotPrice}</td>
                    <td>
                      <Button variant="success"  className={booking.cancelledLate? "hasOverdue": booking.isPayed? "noOverdue": "isNoShow"}>
                        {" "}
                      </Button>{" "}
                    </td>
                    
                  </tr>
                );
              })}
            </tbody>
          </Table>
          
          <button className="submitButton" onClick={()=>dispatch(retrieveBookings(offset))} type="next">
                                            Next
                                        </button>
        </div>
          
        )}
      </Container>
    </div>
  );
};


export default BookingsTable;
