import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useState } from 'react';

import app from './config/firebase-config';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setToken, setAuth} from "./authSlice"



import './App.css';
import LoginForm from './Views/LoginForm';
import Home from './Views/Home';
import SideBar from './Components/SideBar';
import Users from './Views/Users/Users'
import Bookings from './Views/Bookings/Bookings.js';
import AddBookingForm from './Components/AddBookingForm'
import CourtView from './Views/Locations/Locations.js';
import EditCourt from './Views/EditCourt';
import SettingsView from './Views/Settings';
import AddCourt from './Views/AddCourt';
import Coaches from './Views/Coaches/Coaches.js';
import EditCoach from './Views/EditCoach';
import Tournaments from './Views/Tournaments/Tournaments.js';
import AddCoach from './Views/AddCoach';
import AddTournaments from './Views/AddTournaments';
import EditTournaments from './Views/EditTournaments';
import AddUser from './Views/AddUser';
import Acadmeies from './Views/Academies/Academies.js';
import Overdue from './Views/Overdue';
import Notifications from './Views/Notifications/Notifications';
import LocationsReport from './Views/LocationsReport/LocationsReport.js';
import AdminReports from './Views/AdminReport/AdminReport.js';
import TournamentsTable from './Components/TournamentsTable.js';
import RatingsReport from './Views/RatingsReport/RatingsReport';
import InventoryReport from './Views/InventoryReport/InventoryReport';
import CourtAdmins from './Views/CourtAdmins/CourtAdmins';
import Challenges from './Views/Challenges/Challenges';
import ChallengesProgress from './Views/Challenges/ChallengesProgress';
import AddChallenges from './Views/Challenges/AddChallenges';


const myHome = <Home> </Home>
const myBookings = <Bookings> </Bookings>
const myAddBooking = <AddBookingForm> </AddBookingForm>
const myUsers = <Users> </Users>
const myAddUser = <AddUser> </AddUser>
const myCourts = <CourtView> </CourtView>
const myAddCourt = <AddCourt> </AddCourt>
const myEditCourt = <EditCourt> </EditCourt>
const myCoaches = <Coaches> </Coaches>
const myAddCoach = <AddCoach> </AddCoach>
const myEditCoach = <EditCoach> </EditCoach>
const myTournaments = <Tournaments> </Tournaments>
const myAddTournaments = <AddTournaments> </AddTournaments>
const myEditTournaments = <EditTournaments> </EditTournaments>
const myAcademies = <Acadmeies> </Acadmeies>
const myOverdue = <Overdue> </Overdue>
const myNotifications = <Notifications> </Notifications>
const mySettings = <SettingsView> </SettingsView>
const myLocationsReport = <LocationsReport></LocationsReport>
const myAdminReports = <AdminReports></AdminReports>
const myTournamentsTable = <TournamentsTable> </TournamentsTable>
const myRatingsReport = <RatingsReport></RatingsReport>
const myInventoryReport = <InventoryReport></InventoryReport>
const myCourtAdmins = <CourtAdmins></CourtAdmins>
const myChallenges = <Challenges></Challenges>
const myChallengesProgress = <ChallengesProgress></ChallengesProgress>
const myAddChallenges = <AddChallenges></AddChallenges>

function App() {

    const [wrongCredentials, setWrongCredentials] = useState(false);
    const [networkError, setNetworkError] = useState(false);
    const [loading, setLoading] = useState(false);

    const auth = useSelector((state) => state.auth.auth);
    const token = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();
    // const [auth, setAuth] = useState(false 
    //     || window.localStorage.getItem('auth') === 'true'
    //     );
	// const [token, setToken] = useState('');
    

    var email, password;

    const authF = getAuth();

	// useEffect(() => {
	// 	onAuthStateChanged(authF, async (userCred) => {
    //         let token = await userCred?.user?.getIdTokenResult();
    //         console.log("Token: ",  token?.claims?.admin);
	// 		if (userCred) {
    //             console.log("Logging IN 2")
	// 			dispatch(setAuth(true));
	// 			window.localStorage.setItem('auth', 'true');
	// 			userCred.getIdToken().then((tokenT) => {
    //                 window.localStorage.setItem('token', tokenT);
	// 				// dispatch(setToken(tokenT));
    //                 // console.log("Token0: ", token);
	// 			});
	// 		}
	// 	});
	// }, []);


    const signInWithEmailPassword = async (email, password) => {
        setNetworkError(false);
        setWrongCredentials(false);
        setLoading(true);
        signInWithEmailAndPassword(authF, email, password)
            .then(async (userCred) => {
                let token = await userCred?.user?.getIdTokenResult();
                if (userCred && token?.claims?.admin) {
                    console.log("Logging IN")
					dispatch(setAuth(true));
				    window.localStorage.setItem('auth', 'true');
                    userCred.user.getIdToken().then((tokenT) => {
                        window.localStorage.setItem('token', tokenT);
                    });
				} else {
                    throw new Error("Not Admin");
                }
            })
            .catch((error) => {
                console.log("Logging IN error --- ", error)
                if (error.code === "auth/network-request-failed" || error.code === "auth/too-many-requests" || error.code === "auth/internal-error"){
                    setNetworkError(true);
                    console.log("Network Error");
                } else {
                    setWrongCredentials(true);
                }
            });
            setLoading(false);
    };

    const logout = async () => {
        await signOut(authF)
            .then(() => {
                dispatch(setAuth(false));
				window.localStorage.setItem('auth', 'false');
				window.localStorage.removeItem('token');
            }).catch((error) => {
                console.log("Logging OUT error --- ", error)
            });
        window.location.pathname = "/";
    };


    return (<Router>
        <div className="App">
            { auth ? (
                <Switch>
                {/* <Route exact path="/login">
                    <LoginForm 
                        onTypeE={(e)=>{
                            email = e.target.value;
                        }}
                        onTypeP={(e)=>{
                            password = e.target.value;
                        }}
                        onSubmit={(e) => { signInWithEmailPassword(email, password);}}
                         />
                </Route> */}
                <Route exact path="/">
                    <SideBar myHome={myHome}>
                    </SideBar>
                </Route>
                <Route exact path="/users">
                    <SideBar myUsers={myUsers}>
                    </SideBar>
                </Route>
                <Route exact path="/court-admins">
                    <SideBar myCourtAdmins={myCourtAdmins}>
                    </SideBar>
                </Route>
                <Route exact path="/challenges">
                    <SideBar myChallenges={myChallenges}>
                    </SideBar>
                </Route>
                <Route exact path="/challenge-progress">
                    <SideBar myChallenges={myChallengesProgress}>
                    </SideBar>
                </Route>
                <Route exact path="/add-challenges">
                    <SideBar myChallenges={myAddChallenges}>
                    </SideBar>
                </Route>
                <Route exact path="/bookings">
                    <SideBar myBookings={myBookings}>
                    </SideBar>
                </Route>
                <Route exact path="/add-booking">
                    <SideBar myAddBooking={myAddBooking}>
                    </SideBar>
                </Route>
                <Route exact path="/add-users">
                    <SideBar myAddUser={myAddUser}>
                    </SideBar>
                </Route>
                <Route exact path="/courts">
                    <SideBar myCourts={myCourts}>
                    </SideBar>
                </Route>
                <Route exact path="/add-courts">
                    <SideBar myAddCourt={myAddCourt}>
                    </SideBar>
                </Route>
                <Route exact path="/edit-courts">
                    <SideBar myEditCourt={myEditCourt}> </SideBar>
                </Route>
                <Route exact path="/coaches">
                    <SideBar myCoaches={myCoaches}> </SideBar>
                </Route>
                <Route exact path="/add-coaches">
                    <SideBar myAddCoach={myAddCoach}>
                    </SideBar>
                </Route>
                <Route exact path="/edit-coaches">
                    <SideBar myEditCoach={myEditCoach}>
                    </SideBar>
                </Route>
                <Route exact path="/tournaments">
                    <SideBar myTournaments={myTournaments}>
                    </SideBar>
                </Route>
                <Route exact path="/add-tournaments">
                    <SideBar myAddTournaments={myAddTournaments}>
                    </SideBar>
                </Route>
                <Route exact path="/edit-tournaments">
                    <SideBar myEditTournaments={myEditTournaments}>
                    </SideBar>
                </Route>
                <Route exact path="/tournaments-table">
                    <SideBar myTournamentsTable={myTournamentsTable}>
                    </SideBar>
                </Route>
                <Route exact path="/academies">
                    <SideBar myAcademies={myAcademies}>
                    </SideBar>
                </Route>
                <Route exact path="/overdue">
                    <SideBar myOverdue={myOverdue}>
                    </SideBar>
                </Route>
                <Route exact path="/notifications">
                    <SideBar myNotifications={myNotifications}>
                    </SideBar>
                </Route>
                <Route exact path="/settings">
                    <SideBar mySettings={mySettings}>
                    </SideBar>
                </Route>
                <Route exact path="/logout">
                    { () =>{
                        logout(); 
                    }}
                </Route>
                <Route exact path="/admin-report">
                    <SideBar myAdminReports={myAdminReports}>
                    </SideBar>
                </Route>
                <Route exact path="/locations-report">
                    <SideBar myLocationsReport={myLocationsReport}>
                    </SideBar>
                </Route>
                <Route exact path="/rating-report">
                    <SideBar myRatingsReport={myRatingsReport}>
                    </SideBar>
                </Route>
                <Route exact path="/inventory-report">
                    <SideBar myInventoryReport={myInventoryReport}>
                    </SideBar>
                </Route>
            </Switch> 
            ):(
                <div>
                <LoginForm 
                    onTypeE={(e)=>{
                        email = e.target.value;
                    }}
                    onTypeP={(e)=>{
                        password = e.target.value;
                    }}
                    onSubmit={(e) => { 
                        signInWithEmailPassword(email, password);
                    }}
                    loading={loading}
                        />
                       { wrongCredentials ? <p className='login-error'>Wrong Credentials</p> : null}
                       { networkError ? <p className='login-error'>Network Error</p> : null}
                        </div>
            )
        }
            
        </div>
    </Router>
    );
}

export default App;
