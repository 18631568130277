import { useState } from 'react';
import '../../addChallenges.css'

const OpenTrainingCoach = (props) => {
    const [games, setGames] = useState(40);
    const [name, setName] = useState("🏸 Padel Mastery Mentor");
    const [reward, setReward] = useState(100);
    const [duration, setDuration] = useState(30);

    const getChallenge = () => {
        return {
            id: "Open training coach",
            name: name,
            description: "Complete " + games.toString() + " Open Trainings as a Coach.",
            points: reward,
            count: games,
            isNew: true,
            cloudFunction: "ChallengeCompleteXBookingsAsOTCoach",
            order: 1,
            duration: duration,
            isVisible: true
        }
    }

    return (
        <div className="challenge-card">
            <div className="ch-card title">Open Trainings Coach</div>
            <div className="ch-card row">Complete <input value={games} onChange={(ev) => setGames(ev.target.value)} name="hours" className="ch-card number" type="number"/> open trainings as a coach.</div>
            <div className="ch-card row">Name: <input value={name} onChange={(ev) => setName(ev.target.value)} className="ch-card text"/></div>
            <div className="ch-card row">Reward: <input value={reward} onChange={(ev) => setReward(ev.target.value)} className="ch-card number" type="number"/> EGP</div>
            <div className="ch-card row">Duration: <input value={duration} onChange={(ev) => setDuration(ev.target.value)} className="ch-card number" type="number"/> days</div>
            <button className="ch-card add-button" onClick={() =>{ props.addToList(getChallenge()) }}>Add to list</button> 
        </div>
    );
}

const OpenTrainingPlayer = (props) => {
    const [games, setGames] = useState(40);
    const [name, setName] = useState("🌟 Padel Prodigy");
    const [reward, setReward] = useState(100);
    const [duration, setDuration] = useState(30);

    const getChallenge = () => {
        return {
            id: "Open training player",
            name: name,
            description: "Complete " + games.toString() + " Open Trainings as a Player",
            points: reward,
            count: games,
            isNew: true,
            cloudFunction: "ChallengeCompleteXBookingsAsOTPlayer",
            order: 1,
            duration: duration,
            isVisible: true
        }
    }

    return (
        <div className="challenge-card">
            <div className="ch-card title">Open Trainings Player</div>
            <div className="ch-card row">Complete <input value={games} onChange={(ev) => setGames(ev.target.value)} name="hours" className="ch-card number" type="number"/> open trainings as a player.</div>
            <div className="ch-card row">Name: <input value={name} onChange={(ev) => setName(ev.target.value)} className="ch-card text"/></div>
            <div className="ch-card row">Reward: <input value={reward} onChange={(ev) => setReward(ev.target.value)} className="ch-card number" type="number"/> EGP</div>
            <div className="ch-card row">Duration: <input value={duration} onChange={(ev) => setDuration(ev.target.value)} className="ch-card number" type="number"/> days</div>
            <button className="ch-card add-button" onClick={() =>{ props.addToList(getChallenge()) }}>Add to list</button> 
        </div>
    );
}

export { OpenTrainingCoach, OpenTrainingPlayer };