import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {getLocationReportT, getLocationReportWDate} from "./locationsReportAPI";

const initialState = {
    value: [],
    status: "idle",
    editStatus: "idle",
    startDate: new Date(),
    endDate: new Date(),
};



export const retrieveTodaysReport = createAsyncThunk("bookings/getLocationReportT", async() => {
    const response = await getLocationReportT();
    return response;
});

export const retrieveLocationReportWDate = createAsyncThunk("bookings/getLocationReportWDate", async(data) => {
    const response = await getLocationReportWDate(data);
    return response;
});

export const locationsReportsSlice = createSlice({
    name: "locationsReports",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        modifyDates: (state, action) => {
            console.log('This is payload: ', action.payload)
            state.startDate = action.payload[0];
            state.endDate = action.payload[1];
            //[state.startDate, state.endDate] = action.payload
        },
        
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: {
        [retrieveTodaysReport.pending]: (state, action) => {
            state.status = "loading";
        },
        [retrieveTodaysReport.fulfilled]: (state, action) => {
            state.status = "succeeded";
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                }
            } else {
                state.value = action.payload;
            }
            
            
        },
        [retrieveTodaysReport.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        },

        [retrieveLocationReportWDate.pending]: (state, action) => {
            state.status = "loading";
        },
        [retrieveLocationReportWDate.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.value = [];
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                }
            } else {
                state.value = action.payload;
            }
            
            
        },
        [retrieveLocationReportWDate.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        },

    },
});

export const { modifyDates } = locationsReportsSlice.actions;

export const selectAllReports = (state) => state.locationsReports.value;


export default locationsReportsSlice.reducer;