import { useEffect, useState } from 'react';
import TopBar from "../../Components/TopBar"
import Select from 'react-select'
import './addChallenges.css'
import BookingChallenges from './challengesPerCategory/bookingChallenges'
import WalletChallenges from './challengesPerCategory/walletChallenges'
import FindAPlayerChallenges from './challengesPerCategory/findAPlayerChallenges'
import OpenTrainingChallenges from './challengesPerCategory/openTrainingChallenges'
import TournamentChallenges from './challengesPerCategory/tournamentChallenges'
import OtherChallenges from './challengesPerCategory/otherChallenges'
import { useSelector, useDispatch } from "react-redux";
import { addToList, removeFromList, addingChallenge } from './challengesSlice'
import { IoRemoveCircleOutline } from "react-icons/io5";
import { Button } from 'react-bootstrap'

const AddChallenges = () => {
    const [category, setCategory] = useState('bookings')

    const status = useSelector((state) => state.challenges.addStatus);
    const challengesList = useSelector((state) => state.challenges.releaseList);
    const dispatch = useDispatch();

    const categories = [
        { value: 'bookings', label: 'Bookings' },
        { value: 'wallet', label: 'Wallet' },
        { value: 'find_a_player', label: 'Find a Player' },
        { value: 'open_training', label: 'Open Training' },
        { value: 'tournaments', label: 'Tournaments' },
        { value: 'other', label: 'Other' }
    ]

    const addChallengeToList = (challenge) => {
        dispatch(addToList(challenge));
    }

    const removeChallengeFromList = (index) => {
        dispatch(removeFromList(index));
    }

    const displayChallenges = () => {
        if (category === 'bookings') {
            return <BookingChallenges addToList={addChallengeToList} /> 
        } else if (category === 'wallet') {
            return <WalletChallenges addToList={addChallengeToList} />
        } else if (category === 'find_a_player') {
            return <FindAPlayerChallenges addToList={addChallengeToList} />
        } else if (category === 'open_training') {
            return <OpenTrainingChallenges addToList={addChallengeToList} />
        } else if (category === 'tournaments') {
            return <TournamentChallenges addToList={addChallengeToList} />
        } else if (category === 'other') {
            return <OtherChallenges addToList={addChallengeToList} />
        }
        return <div></div>
    }

    const challengesBar = () => {
        if (status === "loading") {
            return <div>Loading...</div>
        } else if (status === "failed") {
            return <div style={{ color: 'red' }}>Failed to add challenges</div>
        } else {
            return <div>
            <strong>Challenges to release:</strong>
                {challengesList.map((challenge, index) => {
                    return (
                        <div key={index} className="to-add-row" id={index}>
                            {index+1}) {challenge.id} ({challenge.name}) 
                            <Button className="to-add-remove-button" onClick={() => removeChallengeFromList(index)}>
                                <IoRemoveCircleOutline />
                            </Button>
                        </div>
                    )})} 
                {status === "succeeded" && <div style={{ color: 'green' }}>Successfully Released</div>}
            </div>
        }
    }

    const releaseChallenges = () => {
        // ask user to confirm
        if (!window.confirm("Are you sure you want to release these challenges?")) {
            return;
        }
        dispatch(addingChallenge(challengesList));
    }

    return (
        <div>
            <TopBar></TopBar>
            <div className="to-add-list">
                {challengesBar()}
                <button className="release-button" onClick={releaseChallenges}>Release Challenges</button>
            </div>
            <div className="challenge-category">
                <strong>Select a category for the challenges:</strong>
                <Select
                    defaultValue={categories[0]}
                    name="challenge-category"
                    options={categories}
                    onChange={(val) => setCategory(val.value)}
                />
            </div>
            <div className="">
                {displayChallenges()}
            </div>
        </div>
    );
}

export default AddChallenges;