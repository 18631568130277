import { useSelector, useDispatch } from "react-redux";
import NotificationForm from "../../Components/NotficationsForm"
import {sendNotification} from "./notificationsSlice"

const Notifications = () => {

    const status = useSelector((state) => state.notifications.status);

    const dispatch = useDispatch();

    const handleSubmit = (title, body) => {
        const data = {
            title: title,
            body: body,
        };
        dispatch(sendNotification(data));
    };

    return (
        <div className="myNotifications">
            <NotificationForm status={status} onSubmit={handleSubmit}></NotificationForm>
        </div>
    );
}

export default Notifications;