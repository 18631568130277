import TopBar from "./TopBar"
import { Row, Col, Form, Button } from 'react-bootstrap'
import SingleSelect from "./MultiselectDropdown";
import { useState } from "react";

const NotificationForm = (props) => {
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");

    const status = props.status;
    const handleSubmit = props.onSubmit;

    return (
        <div className="NotificationForm">
            <TopBar title="Send Notifications"></TopBar>
            <Form>
                <Form.Group>
                    <Form.Label className="myLabel">Message Title</Form.Label>
                    <Form.Control className="" type="text" placeholder="Title" onChange={(ev) => setTitle(ev.target.value)} />
                </Form.Group>
                <Form.Group>
                    <Form.Label className="myLabel">Message Body</Form.Label>
                    <Form.Control className="myMssg" as="textarea" rows={3} placeholder="Your Notification" onChange={(ev) => setBody(ev.target.value)}/>
                </Form.Group>
                {/* <Form.Group></Form.Group> */}
                {/* <Form.Label className="myLabel">Selected Audience</Form.Label>
                <SingleSelect></SingleSelect> */}
                <br></br>
                <br></br>
                <Row>
                    <Col className="myFormControls" lg={10}>
                        <Button className="myButtonForm" variant="primary" onClick={() => handleSubmit(title, body)}>
                            Submit
                        </Button>
                    </Col>
                    <Col className="myFormControls" lg={2}>
                        { status === "loading" && <p>Loading...</p>}
                        { status === "failed" && <p>Failed to send notification</p>}
                        { status === "succeeded" && <p>Notification sent successfully</p>}
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default NotificationForm;