
export function getAllUsers() {
  return new Promise((resolve, reject) => {
    fetch("https://www.jpadel-cms.com/api/users/all", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "authorization": window.localStorage.getItem('token'),
      },
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function getUser(data) {
  return new Promise((resolve, reject) => {
    fetch("https://www.jpadel-cms.com/api/users/single", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "authorization": window.localStorage.getItem('token'),
      },
      body:  JSON.stringify(data),
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function modifyUser(data) {
  return new Promise((resolve, reject) => {
    fetch("https://www.jpadel-cms.com/api/users", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "authorization": window.localStorage.getItem('token'),
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => reject(err));
  });
}

export function addingUser(data) {
  return new Promise((resolve, reject) => {
    fetch("https://www.jpadel-cms.com/api/users", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "authorization": window.localStorage.getItem('token'),
      },
      body: JSON.stringify(data),
    })
      .then(async (res) => {
        if (res.status === 200) {
          resolve(await res.json());
        } else {
          reject(await res.json());
        } 
      })
      .catch((err) => reject(err));
  });
}

export function getUsersSlice(data) {
  return new Promise((resolve, reject) => {
      fetch("https://www.jpadel-cms.com/api/users/getSlice", {
              method: "POST",
              headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "authorization": window.localStorage.getItem('token'),
              },
              body:  JSON.stringify(data),
          })
          .then((res) => {
              resolve(res.json());
          })
          .catch((err) => reject(err));
  });
}
