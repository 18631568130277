import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllTournamentInvitations, modifyTournamentInvitation} from "./tournamentsAPI"

const initialState = {
    value: [],
    status: "idle",
    editStatus: "idle",
    id: '',
};

export const retrieveTournamentInvitations = createAsyncThunk("tournament_invitations/getAllTournamentInvitations", async(data) => {
    const response = await getAllTournamentInvitations(data);
    return response;
});

export const editTournamentInvitation = createAsyncThunk(
    "tournaments/modifyTournamentInvitation",
    async(data) => {
        const response = await modifyTournamentInvitation(data);
        return response;
    }
);




export const tournamentsInvitationsSlice = createSlice({
    name: "tournaments",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        modify: (state, action) => {
            var newData = state.value.map((tournament) => {
                if (tournament.id === action.payload.id)
                    return Object.assign({}, tournament, action.payload);
                return tournament;
            });
            state.value = newData;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: {
        [retrieveTournamentInvitations.pending]: (state, action) => {
            state.status = "loading";
        },
        [retrieveTournamentInvitations.fulfilled]: (state, action) => {
            state.status = "succeeded";
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                }
            } else {
                state.value = action.payload;
            }
        },
        [retrieveTournamentInvitations.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        },
        [editTournamentInvitation.pending]: (state, action) => {
            state.status = "loading";
        },
        [editTournamentInvitation.fulfilled]: (state, action) => {
            state.status = "idle";
            state.value = action.payload;
        },
        [editTournamentInvitation.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        },
    },
});



export const { modify } = tournamentsInvitationsSlice.actions;

export const selectAllInvitations = (state) => state.tournamentsInvitations.value;
export default tournamentsInvitationsSlice.reducer;