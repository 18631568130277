import AddUserForm from "../Components/AddUserForm"
import TopBar from "../Components/TopBar"

const AddUser = () => {
    return (

        <div className="AddUser">
            <TopBar></TopBar>
            <AddUserForm></AddUserForm>
        </div>
    );
}

export default AddUser;