import { useEffect } from "react";
import { Table, Container, Button, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { BsFillTrashFill } from "react-icons/bs";
import { FiMoreHorizontal } from "react-icons/fi";
import SearchBar from "../Components/SearchBar";
import TopBar from "../Components/TopBar";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, Link } from 'react-router-dom';
import {
    retrieveTournamentInvitations,
    selectAllInvitations,
    editTournamentInvitation,
} from "../Views/Tournaments/tournamentsInvitationsSlice";
import Loading from "./Loading";
import "reactjs-popup/dist/index.css";

// import uuidv4 from 'uuid/v4';
// import ProgressBar from './ProgressBar';





const TournamentsTable = () => {
  
  const tournaments = useSelector(selectAllInvitations);
  const status = useSelector((state) => state.tournamentsInvitations.status);
  const location = useLocation()
  const dispatch = useDispatch();
  let tournamentId = location.state.id;

  console.log('This is tournament id: ', tournamentId);
  
  useEffect(() => {
    if(status == 'idle')  
    dispatch(retrieveTournamentInvitations({id:tournamentId })); 
  },[]);
//  const [setState] = useState('');
  // const [file , setFile] = useState('');
  // const upload = ()=>{
  //   if(file == null)
  //     return;
  //   storage.ref(`/images/${file.name}`).put(file).then (()=>{
  //     debugger;
  //         console.log("File uploaded");
  //         debugger;
  //       });
  // }

//   // <input type="file"   onChange={(e)=>{setFile(e.target.files[0])}}/>
//   // <Button className="addCourtButton" type="button" onClick={upload} variant="primary">Upload</Button>


//   const state ={
//     storageRef: storage.ref(), // ADD THIS LINE
//     percentUploaded: 0, // ADD THIS LINE TOO
//     uploadTask: null, // ADD THIS LINE TOO, SEE BELOW
//     file: null,
//     uploadState: null,
//     errors: []
//   }
 
//   const addFile = event => {
//     const file = event.target.files[0];
//     if (file) {
//       this.setState({ file });
//     }
//   };
//   this.addFile = this.addFile.bind(this);

//   const uploadFile = (file) => {
//     // location in storage you want to create/send file to
//     const filePath = `trades/images/${uuidv4()}.jpg`;

//     this.setState({
//       uploadTask: state.storageRef.child(filePath).put(file)
//     },
//       () => {
//           state.uploadTask.on(
//           'state_changed', 
//           snap => {
//             const percentUploaded = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
//            this.setState({ percentUploaded }); 
//         },
//           err => {
//             console.error(err);
//            this.setState({
//               errors: state.errors.concat(err),
//               uploadState: 'error',
//               uploadTask: null
//             });
//           },
//           () => {
//             state.uploadTask.snapshot.ref.getDownloadURL().then(downloadUrl => {
//               console.log('this is the image url', downloadUrl);
//               this.setState({ uploadState: 'done' })
//             })
//             .catch(err => {
//               console.error(err);
//               this.setState({
//                 errors: state.errors.concat(err),
//                 uploadState: 'error',
//                 uploadTask: null
//               })
//             })
//           }
//         )
//       }
//     )
// }

// const handleSubmit = event => {
//   uploadFile(state.file);
//  };

// const componentWillUnmount= () =>{
//    if (state.uploadTask !== null) {
//      state.uploadTask.cancel();
//      this.setState({ uploadTask: null });
//    }
//   }
  



{/* <Form onSubmit={handleSubmit}> 
          <input
              onChange={addFile}
              disabled={state.uploadState === 'uploading'}
              name="file"
              type="file"
           />
          <Button className="addCourtButton" type="button" onClick={handleSubmit} variant="primary">Upload</Button>
        </Form>

     <br></br>       <br></br>
     <ProgressBar 
  uploadState={state.uploadState} 
  percentUploaded={state.percentUploaded}
/> */}

  const handleClick = (flag, id) =>{
    //if flag == true --> Accept invitation
    //if flag == false --> Reject invitration
    debugger;
    if(flag){
      let payload = {
        data : {status : 'Accepted by JPadel'},
        id : id
      }
    dispatch(editTournamentInvitation(payload));
   
    }else{
      let payload = {
        data : {status : 'Rejected by JPadel'},
        id : id
      }
    dispatch(editTournamentInvitation(payload));
   
    }
     
  }
  console.log('This is status: ', status);
  return (
    <div className="myTournamentInvitations">
      <TopBar title="All Tournament Invitations"></TopBar>
      <Link to={{
                                         pathname: "/edit-tournaments",
                                         state: {
                                            id: tournamentId,
                                                },
                                         }}> 
      <Button className="addCourtButton" type="button" variant="primary">Edit</Button>
      </Link>
      <br></br>       <br></br>  

      <Container>
        <SearchBar></SearchBar>
        {status === "loading"  ? (
          <div className="loadingWrapper">
            <Loading
              color="#DD926A"
              type="spin"
              width="20%"
              height="20%"
            ></Loading>
          </div>
        ) : (
          <Table bordered hover responsive="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Sender Name</th>
                <th>Recipient Name(ID)</th>
                <th>Status</th>
                <th>Update</th>
              </tr>
            </thead>
            <tbody>
              {tournaments.map((tournament, index) => {
                  
                  console.log('Inside !!');
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{tournament.senderUsername}</td>
                    <td>{tournament.recipientUsername}</td>
                    <td>{tournament.status}</td>
                    {tournament.status == 'Awaiting partner approval' || 
                    tournament.status == 'Rejected by JPadel' || 
                    tournament.status == 'Accepted by JPadel' ? 
                    <td>
                      <div>
                       <Button className="myCircleButtonGreen"  onClick={() => handleClick(true, tournament.id)}></Button>
                       <Button className="myCircleButtonRed"  onClick={() => handleClick(false, tournament.id)}></Button>
                       </div>  
                    </td> :
                    <td></td>
                    }
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </Container>
    </div>
  );
};


export default TournamentsTable;
