import { Row, Col, Form, Button } from 'react-bootstrap'


const AddCoachForm = () => {
    return (
        <Form>
            <Form.Group >
                <Form.Label className="myLabel">Username</Form.Label>
                <Form.Control className="myInputField" type="text" placeholder="Enter username" />
            </Form.Group>
            <Form.Group>
                <Form.Label className="myLabel">Password</Form.Label>
                <Form.Control className="myInputField" type="password" placeholder="Password" />
            </Form.Group>
            <Form.Label className="myLabel">Date</Form.Label>
            <Form.Control type="date" name='date_of_birth' />
            <br></br>
            <br></br>
            <Row>
                <Col lg={6} >
                    <Button className="myButtonFormAdd" variant="primary" type="submit">
                        Submit
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}

export default AddCoachForm;