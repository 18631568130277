import TopBar from "../../Components/TopBar.js";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    retrieveTodaysReport,
    modifyDates,
    selectAllReports,
    retrieveAdminReportWDate,
} from "./adminReportSlice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { Table, Container, Button, Row, Col } from "react-bootstrap";
// import SearchBar from "../../Components/SearchBar";
import Loading from "../../Components/Loading";
import Popup from "reactjs-popup";
import Form from "react-bootstrap/Form";
import {generateExcelReport} from "./adminReportAPI";
const AdminReports = () => {

    const reports = useSelector(selectAllReports);
    const status = useSelector((state) => state.adminReports.status);
    const editStatus = useSelector((state) => state.adminReports.editStatus);
    const startDate = useSelector((state) => state.adminReports.startDate);
    const endDate = useSelector((state) => state.adminReports.endDate);

    const dispatch = useDispatch();
    useEffect(() => {
        if (status === "idle") {
           
         dispatch(retrieveTodaysReport());
         // console.log('From use effect: ', bookings)
    }
  }, [status, dispatch]);

    
    const onChange = (dates) => {
        console.log('These are dates: ', dates)
        dispatch(modifyDates(dates))
    };

    const handleClick = () => {
        dispatch(retrieveAdminReportWDate({startDate: startDate, endDate:endDate}))
    }
    
    const hadleExtractForm = (e)=>{
        debugger;
        var emailToSendTo = e.target.emailList.value;
        let reportsArr = [];
        Object.entries(reports).map((rep,index)=>{
            if(rep[0]!='undefined'){
            var obj = {
                cashier: rep[0],
                ...rep[1], 
            }
            reportsArr.push(obj);
            }
        })
        debugger;
        var data = {emailToSendTo: emailToSendTo, report:reportsArr};
        generateExcelReport(data);
        console.log('Done !');
    }

    return (
        <div className="AdminReports">
            <TopBar title="Court Admin Report"></TopBar>
            <Popup
            trigger ={
                <div>
                    <Button className="extractReport" type="button" variant="primary">Extract</Button>
                </div>}
                modal
            >

                                    <Form className="editForm" id={'generateReport'}  onSubmit={hadleExtractForm}>
                                            <Form.Group as={Row} controlId="emailList" size="lg">
                                                <Form.Label column sm="3">Email List</Form.Label>
                                                <Col>
                                                    <Form.Control
                                                        autoFocus
                                                        type="text"
                                                    />
                                                </Col>
                                            </Form.Group>
                                            </Form>
                                            <button className="submitButton" form={'generateReport'} type="submit">
                                            Submit
                                        </button>

            </Popup>
            <br></br>
            <br></br>
            <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
            />
            <Button className="fetchData" onClick={handleClick} type="button" variant="primary">Fetch data</Button>

            <br></br><br></br>
            <Container>

            {status === "loading" || editStatus === "loading" ? (
          <div className="loadingWrapper">
            <Loading
              color="#DD926A"
              type="spin"
              width="20%"
              height="20%"
            ></Loading>
          </div>
        ) :
            (<Table bordered hover responsive="sm">
                <thead>
                    <tr>
                        <th>Cashier Name</th>
                        <th>Location</th>
                        <th>Total Cash</th>
                        <th>Total Visa</th>
                        <th>Date</th>
                        <th>Cancelled</th>
                        <th>Total Bookings</th>
                    </tr>
                </thead>
                <tbody>
                {
                       Object.entries(reports).map((rep,index)=>{

                           //debugger;
                           if(rep[0]!='undefined')
                        return (
                            <tr key={index}>
                              <td>{rep[0]}</td>
                              <td>{rep[1].location}</td>
                              <td>{Math.round(rep[1].totalCash)}</td>
                              <td>{Math.round(rep[1].totalVisa)}</td>
                              <td>{rep[1].date}</td>
                              <td>{rep[1].cancelledCounter}</td>
                              <td>{rep[1].totalCounter}</td>
                            </tr>
                          );
                       }) 
                    }
                </tbody>
            </Table>)}
        </Container>
        </div>
    );
}

export default AdminReports;