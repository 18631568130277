import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllUsers, modifyUser, getUsersSlice, getUser, addingUser } from "./usersAPI";

const initialState = {
    value: [],
    status: "idle",
    editStatus: "idle",
    addStatus: "idle",
    offset: 0,
    error: "",
};

export const retrieveUsers = createAsyncThunk("users/getAllUsers", async() => {
    const response = await getAllUsers();
    return response;
});

export const retrieveUser = createAsyncThunk("users/getUser", async(data) => {
    data = {'search': data};
    const response = await getUser(data);
    return response;
});

export const editUser = createAsyncThunk("users/modifyUser", async(data) => {
        const response = await modifyUser(data);
        return response;
    }
);

export const addUser = createAsyncThunk("users/addUser", async(data) => {
        const response = await addingUser(data);
        return response;
    }
);

export const retrieveUsersSlice = createAsyncThunk("users/getUsersSlice", async(data) => {
    data = {'offset': data}
    // console.log('This is offset: ', data)
    const response = await getUsersSlice(data);
    return response;
});

export const usersSlice = createSlice({
    name: "users",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        modify: (state, action) => {
            var newData = state.value.map((user) => {
                if (user.id === action.payload.id)
                    return Object.assign({}, user, action.payload);
                return user;
            });
            state.value = newData;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: {
        [retrieveUsers.pending]: (state, action) => {
            state.status = "loading";
        },
        [retrieveUsers.fulfilled]: (state, action) => {
            state.status = "succeeded";
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                }
            } else {
                state.value = action.payload;
            }
        },
        [retrieveUsers.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        },
        [editUser.pending]: (state, action) => {
            state.editStatus = "loading";
        },
        [editUser.fulfilled]: (state, action) => {
            state.editStatus = "succeeded";
            // Add any fetched posts to the array
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                }
            } else {
                state.value = action.payload;
            }
        },
        [editUser.rejected]: (state, action) => {
            state.editStatus = "failed";
            state.error = action.error.message;
        },

        [addUser.pending]: (state, action) => {
            state.addStatus = "loading";
        },
        [addUser.rejected]: (state, action) => {
            state.addStatus = "failed";
            state.error = action.error.message;
        },
        [addUser.fulfilled]: (state, action) => {
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                    state.addStatus = "failed";
                    state.error = action.payload.message;
                } else {
                    state.addStatus = "succeeded";
                }
            }
        },
        
        [retrieveUsersSlice.pending]: (state, action) => {
            state.status = "loading";
        },
        [retrieveUsersSlice.fulfilled]: (state, action) => {
            state.status = "succeeded";
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                }
            } else {
                state.value.push(...action.payload);
                state.offset += 20;
            }
        },
        [retrieveUsersSlice.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        },

        [retrieveUser.pending]: (state, action) => {
            state.status = "loading";
        },
        [retrieveUser.fulfilled]: (state, action) => {
            state.status = "succeeded";
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                }
            } else {
                state.offset = 0;
                state.value = action.payload;
            }
        },
        [retrieveUser.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        },
    },
});

export const { modify } = usersSlice.actions;

export const selectAllUsers = (state) => state.users.value;
export const { toggleUserEditMode } = usersSlice.actions;

export default usersSlice.reducer;