import { useState, useEffect } from 'react';
import Select from 'react-select';
import '../../addChallenges.css'
import { useSelector, useDispatch } from 'react-redux';
import { retrieveActiveTournamets } from '../../../Tournaments/tournamentsSlice';

const TournamentEarlyBird = (props) => {
    const [people, setPeople] = useState(8);
    const [tournamentId, setTournamentId] = useState("");
    const [tournamentName, setTournamentName] = useState("");
    const [name, setName] = useState("Pro");
    const [reward, setReward] = useState(100);
    const [duration, setDuration] = useState(30);
    
    const tournaments = useSelector((state) => state.tournaments.value);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(retrieveActiveTournamets());
    }, []);
    const tournamentOptions = tournaments.map((tournament) => {
        return {value: tournament.id, label: tournament.tournamentName}
    });

    const getChallenge = () => {
        console.log(tournaments);
        return {
            id: "Tournament Early bird",
            name: name,
            description: "Be one of the first " + people.toString() + " to register for the " + tournamentName + " tournament.",
            points: reward,
            count: people,
            isNew: true,
            order: 1,
            duration: duration,
            isVisible: true
        }
    }

    const choseTournament = (val) => {
        setTournamentId(val.value);
        setTournamentName(val.label);
    }

    return (
        <div className="challenge-card">
            <div className="ch-card title">Tournament Early Bird</div>
            <div className="ch-card row">Be one of the first <input value={people} onChange={(ev) => setPeople(ev.target.value)} name="hours" className="ch-card number" type="number"/> to register for the <Select onChange={choseTournament} className="ch-card select" options={tournamentOptions}/> tournament.</div>
            <div className="ch-card row">Name: <input value={name} onChange={(ev) => setName(ev.target.value)} className="ch-card text"/></div>
            <div className="ch-card row">Reward: <input value={reward} onChange={(ev) => setReward(ev.target.value)} className="ch-card number" type="number"/> EGP</div>
            <div className="ch-card row">Duration: <input value={duration} onChange={(ev) => setDuration(ev.target.value)} className="ch-card number" type="number"/> days</div>
            <button className="ch-card add-button" onClick={() =>{ props.addToList(getChallenge()) }}>Add to list</button> 
        </div>
    );
}

export { TournamentEarlyBird };