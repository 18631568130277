import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBookingSlice, getBookingsToday, getBookingsForUser} from "./bookingsAPI";

const initialState = {
    value: [],
    status: "idle",
    editStatus: "idle",
    offset: 0,
};

export const retrieveBookings = createAsyncThunk("bookings/getBookingSlice", async(data) => {
    data = {'offset': data}
    console.log('This is offset: ', data)
    debugger;
    const response = await getBookingSlice(data);
    return response;
});

export const retrieveTodaysBookings = createAsyncThunk("bookings/getBookingsToday", async() => {
    const response = await getBookingsToday();
    return response;
});

export const retrieveBookingsForUser = createAsyncThunk("bookings/getBookingsForUser", async(data) => {
    data = {'search': data};
    const response = await getBookingsForUser(data);
    return response;
});

export const bookingSlice = createSlice({
    name: "bookings",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        modify: (state, action) => {
            var newData = state.value.map((user) => {
                if (user.id === action.payload.id)
                    return Object.assign({}, user, action.payload);
                return user;
            });
            state.value = newData;
        },
        
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: {
        [retrieveBookings.pending]: (state, action) => {
            state.status = "loading";
        },
        [retrieveBookings.fulfilled]: (state, action) => {
            state.status = "succeeded";
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                }
            } else {
                state.value.push(...action.payload);
                state.offset += 20;
            }
        },
        [retrieveBookings.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        },

        [retrieveTodaysBookings.pending]: (state, action) => {
            state.status = "loading";
        },
        [retrieveTodaysBookings.fulfilled]: (state, action) => {
            state.status = "succeeded";
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                }
            } else {
                state.value = action.payload;
            }
        },
        [retrieveTodaysBookings.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        },

        [retrieveBookingsForUser.pending]: (state, action) => {
            state.status = "loading";
        },
        [retrieveBookingsForUser.fulfilled]: (state, action) => {
            state.status = "succeeded";
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                }
            } else {
                state.value = action.payload;
            }
        },
        [retrieveBookingsForUser.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        },
    },
});

export const { modify } = bookingSlice.actions;

export const selectAllBookings = (state) => state.bookings.value;


export default bookingSlice.reducer;