import { useEffect } from "react";
import { Table, Container, Button, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from 'react-bootstrap/InputGroup'
import SearchBar from "../../Components/SearchBar";
import TopBar from "../../Components/TopBar";
import { BsPencil } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import {
  selectAllCourtAdmins,
  modify,
  addLoc,
  editCourtAdmin,
  retrieveCourtAdmins,
  retrieveCourtAdmin,
  addCourtAdmin
} from "./courtAdminsSlice";
import Loading from "../../Components/Loading";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useState } from 'react';


const CourtAdmin = () => {

    const courtAdmins = useSelector(selectAllCourtAdmins);
    const status = useSelector((state) => state.courtAdmins.status);
    const editStatus = useSelector((state) => state.courtAdmins.editStatus);
    const addStatus = useSelector((state) => state.courtAdmins.addStatus);
    const addStatusMessage = useSelector((state) => state.courtAdmins.addStatusMessage);
    const offset = useSelector((state)=> state.courtAdmins.offset);
    const error = useSelector((state) => state.courtAdmins.error);

    var search = "";

    const [type, setType] = useState('');

  const dispatch = useDispatch();
  useEffect(() => {
    if (status === "idle") {
      dispatch(retrieveCourtAdmins());
    }
  }, [status, offset, dispatch]);

  const handleSubmit = (e) => {

    let locs = [];
    for (const courtAdmin of courtAdmins.courtAdminData){
      if (courtAdmin.uid === e.target.id){
        locs = courtAdmin.allowedLocations;
        break;
      }
    } 

    e.preventDefault();
    let data = {
      id: e.target.id,
      data: {
        allowedLocations: 
          locs.map((location) => {
            for (const [key, value] of Object.entries(courtAdmins.locationNames)) {
              if (value === location) {
                return key;
              }
            }
            return "";
          }),

      },
    };
    console.log(data);
    dispatch(editCourtAdmin(data));
    if (editStatus === "succeeded") dispatch(retrieveCourtAdmins());
  };

  const handleAdd = (e) => {
    e.preventDefault();
    if (e.target.fullName.value === "" || e.target.username.value === "" || e.target.mobileNumber.value === "" || e.target.email.value === "" || e.target.password.value === "") {
      return alert("Please fill in all the fields");
    }
    let data = {
      fullName: e.target.fullName.value,
      username: e.target.username.value,
      mobileNumber: e.target.mobileNumber.value,
      email: e.target.email.value,
      password: e.target.password.value,
    }
    dispatch(addCourtAdmin(data));
    // window.location.reload(false);
  }

  const addCourtAdminForm = () => {
    return (
      <Popup
        trigger={<Button className="addCourtButton" type="button" variant="primary">Add Court Admin</Button>}
        modal
        nested
      >
        <Form id={"addUser"} onSubmit={handleAdd}>
          <Form.Group controlId="fullName">
            <Form.Label>Full Name</Form.Label>
            <Form.Control type="text" placeholder="Enter Full Name" />
          </Form.Group>
          <Form.Group controlId="username">
            <Form.Label>Username</Form.Label>
            <Form.Control type="text" placeholder="Enter Username" />
          </Form.Group>
          <Form.Group controlId="mobileNumber">
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control type="text" placeholder="Enter Mobile Number" />
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="text" placeholder="Enter Email" />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control type="text" placeholder="Enter Password" />
          </Form.Group>
        </Form>
        <button form={"addUser"} type="submit">
          Submit
        </button>
        {addStatus ? <Button variant="success" className="noOverdue" onClick={dispatch(retrieveCourtAdmins())}>
                        {"Successfully added court admin"}
                      </Button> : null}
        {addStatusMessage === "loading" &&
            <Loading
              color="#DD926A"
              type="spin"
              width="20%"
              height="20%"
            ></Loading>
        }
        {addStatusMessage === "failed" && <div style={{ color: 'red' }}>{error}</div>}
      </Popup>
    );
  };

  return (
    <div className="myCourtAdmins">
      <TopBar title="All Court Admins"></TopBar>
      {addCourtAdminForm()}
      <br></br>       <br></br>

      <Container>
        <SearchBar 
          handleSearch={(e)=>{search = e.target.value;}} 
          handleClick={()=>{dispatch(retrieveCourtAdmin(search));}} 
          keyPress={(e)=>{e.key === 'Enter' && dispatch(retrieveCourtAdmin(search))}}
          placeholder="Search by Full Name, Number, or Email"></SearchBar>
        {status === "loading" || editStatus === "loading" ? (
          <div className="loadingWrapper">
            <Loading
              color="#DD926A"
              type="spin"
              width="20%"
              height="20%"
            ></Loading>
          </div>
        ) : (
          <div>
          <Table bordered hover responsive="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Email</th>
                <th>uid</th>
                <th>Status</th>
                <th>Accessed Locations</th>
                <th>...</th>
              </tr>
            </thead>
            <tbody>
              {/* {console.log(courtAdmins)} */}
        {
              courtAdmins && courtAdmins.courtAdminData ? courtAdmins.courtAdminData.map((user, index) => {
                return (
                  <tr key={index}>
                    <td>{index}</td>
                    <td>{user.email}</td>
                    <td>{user.uid}</td>
                    <td>{user.disabled ?
                      <Button variant="success" className="isLateCancelled">
                        {"Disabled"}
                      </Button>
                      :
                      <Button variant="success" className="noOverdue">
                        {"Active"}
                      </Button>
                    }
                    </td>
                    <td>{user.allowedLocations ? user.allowedLocations.map((element, index) => {
                        return <div>{index+1}- {element}</div>
                    }): <div></div>}</td>
                    <td>
                      <Row>
                        <Col xs={4}>
                          <Popup
                            trigger={<button className="myEditIcon"> <BsPencil></BsPencil>  </button>}
                            modal
                            // onClose={() => dispatch(retrieveCourtAdmins())}
                            onClose={() => console.log("closing")}
                          >
                            <Form id={user.uid} onSubmit={handleSubmit}>
                              <Form.Group controlId="email" size="lg">
                                <Form.Label style={{fontWeight: "bold"}}>Email</Form.Label>
                                <Form.Control
                                  disabled
                                  defaultValue={user.email}
                                />
                              </Form.Group>
                              {/* <Form.Group controlId="disabled" size="lg">
                                <Form.Check 
                                  type="switch"
                                  id="custom-switch"
                                  label="Active"
                                  defaultChecked={!user.disabled}
                                />
                              </Form.Group> */}
                              <Form.Group controlId="allowedLocations" size="lg">
                                <Form.Label style={{fontWeight: "bold"}}>Accessed Locations</Form.Label>
                                {
                                  user.allowedLocations ? user.allowedLocations.map((element, index) => {
                                    return <InputGroup className="mb-3" id={element}>
                                    <Form.Control
                                      aria-describedby="basic-addon2"
                                      defaultValue={element}
                                      disabled
                                    />
                                    <Button variant="outline-secondary" id="button-addon2" 
                                      onClick={()=> {
                                        let u = Object.assign({}, user, {
                                          ["allowedLocations"]: user.allowedLocations.filter((x) => x !== element)
                                        });
                                        dispatch(addLoc(u));
                                      }}>
                                      Remove
                                    </Button>
                                  </InputGroup>
                                  }) : <div></div>
                                }
                              </Form.Group>
                              <Form.Group controlId="fullName" size="lg">
                                <InputGroup className="mb-3">
                                  <Form.Control
                                    as="select"
                                    // value="loc"
                                    value={type}
                                    onChange={e => {
                                      // console.log("e.target.value", e.target.value);
                                      setType(e.target.value);
                                    }}
                                    // onChange={(val) => {
                                    //   let u = Object.assign({}, user, {
                                    //     ["fullName"]: val.target.value,
                                    //   });
                                    //   dispatch(modify(u));
                                    // }}
                                  >
                                    <option value="loc">Choose a location</option>
                                    {Object.entries(courtAdmins.locationNames).map((element, index) => {
                                      return <option value={element[1]}>{element[1]}</option>
                                    })}
                                  </Form.Control>
                                  <Button variant="outline-secondary" id="button-addon2" 
                                    onClick={()=> {
                                      let u = Object.assign({}, user, {
                                        ["allowedLocations"]: [...user.allowedLocations, type],
                                      });
                                      dispatch(addLoc(u));
                                    }}>
                                    Add
                                  </Button>
                                </InputGroup>
                              </Form.Group>
                            </Form>
                            <button form={user.uid} type="submit">
                              Submit
                            </button>
                          </Popup>
                        </Col>
                        {/* <Col xs={4}>
                          <FiMoreHorizontal className="myMoreIcon"></FiMoreHorizontal>
                        </Col> */}
                      </Row>
                    </td>
                  </tr>
                );
              }) : <div></div> }
            </tbody>
          </Table>
        </div>
        )}
      </Container>
    </div>
  );
};

export default CourtAdmin;