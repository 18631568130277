import { useState } from 'react';
import '../../addChallenges.css'

const BookingHour = (props) => {
    const [hours, setHours] = useState(8);
    const [name, setName] = useState("🥉 Rookie");
    const [reward, setReward] = useState(40);
    const [duration, setDuration] = useState(10);

    const getChallenge = () => {
        return {
            id: "Bookings hour",
            name: name,
            description: "Complete " + hours.toString() + " hours of bookings",
            points: reward,
            count: hours,
            isNew: true,
            cloudFunction: "ChallengeCompleteXBookings",
            order: 1,
            duration: duration,
            isVisible: true
        }
    }

    return (
        <div className="challenge-card">
            <div className="ch-card title">Bookings hour</div>
            <div className="ch-card row">Complete <input value={hours} onChange={(ev) => setHours(ev.target.value)} name="hours" className="ch-card number" type="number"/> hours of bookings</div>
            <div className="ch-card row">Name: <input value={name} onChange={(ev) => setName(ev.target.value)} className="ch-card text"/></div>
            <div className="ch-card row">Reward: <input value={reward} onChange={(ev) => setReward(ev.target.value)} className="ch-card number" type="number"/> EGP</div>
            <div className="ch-card row">Duration: <input value={duration} onChange={(ev) => setDuration(ev.target.value)} className="ch-card number" type="number"/> days</div>
            <button className="ch-card add-button" onClick={() =>{ props.addToList(getChallenge()) }}>Add to list</button> 
        </div>
    );
}

export default BookingHour;