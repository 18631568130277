import { Button, Row, Col } from "react-bootstrap"
import CoachCard from "..//..//Components/CoachCard"
import TopBar from "..//..//Components/TopBar"


const Coaches = () => {
    return (
        <div className="myCoaches">
            <TopBar title="All Coaches"></TopBar>
            <Button className='myEditCourtButton' onClick={event => window.location.href = '/add-coaches'}>Add Coach</Button>
            <br></br> <br></br>
            <Row>
                <Col lg={4}>
                    <CoachCard></CoachCard>
                </Col>
                <Col lg={4}>
                    <CoachCard></CoachCard>
                </Col >
                <Col lg={4}>
                    <CoachCard></CoachCard>
                </Col>
            </Row>
            <br></br>
            <Row>
                <Col lg={4}>
                    <CoachCard></CoachCard>
                </Col>
                <Col lg={4}>
                    <CoachCard></CoachCard>
                </Col >
                <Col lg={4}>
                    <CoachCard></CoachCard>
                </Col>
            </Row>
        </div>
    );

}

export default Coaches;