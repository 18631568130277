import TopBar from "./TopBar"
import { Row, Col, Form, Button, Dropdown, DropdownButton } from 'react-bootstrap'


const AddBookingForm = () => {
    return (
        <div className="AddBookingForm">
            <TopBar title="Add Booking"></TopBar>
            <Form>
                <Form.Group >
                    <Form.Label className="myLabel">Location Name</Form.Label>
                    <Form.Control className="myInputField" type="text" placeholder="Location Name" />
                </Form.Group>
                <Form.Group >
                    <Form.Label className="myLabel">Court Name</Form.Label>
                    <Form.Control className="myInputField" type="text" placeholder="Court Name" />
                </Form.Group>
                <Form.Group>
                    <Form.Label className="myLabel">Mobile Number</Form.Label>
                    <Form.Control className="myInputField" type="text" placeholder="Mobile Number" />
                </Form.Group>
                <Form.Group>
                    <Form.Label className="myLabel">Email</Form.Label>
                    <Form.Control className="myInputField" type="text" placeholder="Email" />
                </Form.Group>
                <Form.Group>
                    <Form.Label className="myLabel">Deposit</Form.Label>
                    <Form.Control className="myInputField" type="text" placeholder="Deposit" />
                </Form.Group>
                <Form.Group>
                    <Form.Label className="myLabel">Overdue</Form.Label>
                    <Form.Control className="myInputField" type="text" placeholder="Deposit" defaultValue="0" />
                </Form.Group>
                <Form.Label className="myLabel">Date of Birth</Form.Label>
                <Form.Control type="date" name='date_of_birth' />
                <br></br>
                <Form.Label className="myLabel">Role</Form.Label>
                <DropdownButton className="myDropDown" id="dropdown-basic-button" title="Role">
                    <Dropdown.Item className="myDropDownText" href="#/action-1">Player</Dropdown.Item>
                    <Dropdown.Item className="myDropDownText" href="#/action-2">Court Admin</Dropdown.Item>
                    <Dropdown.Item className="myDropDownText" href="#/action-3">Super Admin</Dropdown.Item>
                </DropdownButton>
                <br></br>
                <br></br>
                <Row>
                    <Col lg={6} >
                        <Button className="myButtonFormAdd" variant="primary" type="submit">
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default AddBookingForm;