const SecondaryCard = () => {
    return (

        <div className="mySecondaryCard">
            <h1>ehh</h1>
            <div className="mySecondaryTooltip">
                <h1>ehh2</h1>
            </div>
        </div>
    );
}

export default SecondaryCard;