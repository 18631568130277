import '../addChallenges.css'
import {OpenTrainingCoach, OpenTrainingPlayer} from "./challenges/open_training"

const OpenTrainingChallenges = (props) => {

    return (
        <div>
            <div className="challenges-grid">
                <OpenTrainingCoach addToList={props.addToList} />
                <OpenTrainingPlayer addToList={props.addToList} />
            </div>
        </div>
    );
}

export default OpenTrainingChallenges;