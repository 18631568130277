import React, { Component, Fragment } from 'react';

import Select from 'react-select';



const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
]


// type State = {
//     isClearable: boolean,
//     isDisabled: boolean,
//     isLoading: boolean,
//     isRtl: boolean,
//     isSearchable: boolean,
// };

export default class SingleSelect extends Component {
// export default class SingleSelect extends Component<*, State> {
    state = {
        isClearable: true,
        isDisabled: false,
        isLoading: false,
        isRtl: false,
        isSearchable: true,
    };

    toggleClearable = () =>
        this.setState(state => ({ isClearable: !state.isClearable }));
    toggleDisabled = () =>
        this.setState(state => ({ isDisabled: !state.isDisabled }));
    toggleLoading = () =>
        this.setState(state => ({ isLoading: !state.isLoading }));
    toggleRtl = () => this.setState(state => ({ isRtl: !state.isRtl }));
    toggleSearchable = () =>
        this.setState(state => ({ isSearchable: !state.isSearchable }));
    render() {
        const {
            isClearable,
            isSearchable,
            isDisabled,
            isLoading,
            isRtl,
        } = this.state;
        return (
            <Fragment>
            <Select
                    className= "basic-multi-select"
        classNamePrefix = "select"
        isDisabled = { isDisabled }
        isLoading = { isLoading }
        isClearable = { isClearable }
        isRtl = { isRtl }
        isSearchable = { isSearchable }
        isMulti
        name = "cars"
        options = { options }

            />

            </Fragment>
        );
    }
}