import '../addChallenges.css'
import {Feedback, ManuallyTracked} from "./challenges/other"

const OtherChallenges = (props) => {

    return (
        <div>
            <div className="challenges-grid">
                <Feedback addToList={props.addToList} />
                <ManuallyTracked addToList={props.addToList} />
            </div>
        </div>
    );
}

export default OtherChallenges;