import { useState } from 'react';
import Select from 'react-select'
import '../../addChallenges.css'

const BookingBeforeHour = (props) => {
    const [hours, setHours] = useState(4);
    const [beforehour, setBeforeHour] = useState("16:00");
    const [name, setName] = useState("🕊️ Early Bird");
    const [reward, setReward] = useState(100);
    const [duration, setDuration] = useState(15);
    
    const getChallenge = () => {
        return {
            id: "Booking before hour",
            name: name,
            description: "Complete " + hours.toString() + " hours of bookings before " + beforehour,
            points: reward,
            count: hours,
            isNew: true,
            cloudFunction: "ChallengeCompleteXBookings",
            order: 1,
            duration: duration,
            isVisible: true,
            beforeHour: getHourAsInt(beforehour),
        }
    }

    const getHourAsInt = (time) => {
        return parseInt(time.split(':')[0])
    }

    const changeTime = (e) => {
        let hour = e.target.value.split(':')[0]
        e.target.value = `${hour}:00`
        setBeforeHour(e.value);
    }

    return (
        <div className="challenge-card">
            <div className="ch-card title">Bookings before hour</div>
            <div className="ch-card row">Complete <input value={hours} onChange={(ev) => setHours(ev.target.value)} name="hours" className="ch-card number" type="number"/> hours of booking before <input value={beforehour} onChange={changeTime} className="ch-card time" type="time"/></div>
            <div className="ch-card row">Name: <input value={name} onChange={(ev) => setName(ev.target.value)} className="ch-card text"/></div>
            <div className="ch-card row">Reward: <input value={reward} onChange={(ev) => setReward(ev.target.value)} className="ch-card number" type="number"/> EGP</div>
            <div className="ch-card row">Duration: <input value={duration} onChange={(ev) => setDuration(ev.target.value)} className="ch-card number" type="number"/> days</div>
            <button className="ch-card add-button" onClick={() =>{ props.addToList(getChallenge())}}>Add to list</button>
        </div>
    );
}

export default BookingBeforeHour;