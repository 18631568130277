import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllLocations, modifyLocation, addLocation } from "./locationsAPI";

const initialState = {
    value: [],
    status: "idle",
    editStatus: "idle",
    requireIdcheck: [],
    priceAllBool: false,
};

export const retrieveLocations = createAsyncThunk("locations/getAllLocations", async() => {
    const response = await getAllLocations();
    return response;
});

export const editLocation = createAsyncThunk(
    "locations/modifyLocation",
    async(data) => {
        console.log('Test checkbox', data);
        const response = await modifyLocation(data);
        return response;
    }
);

export const addLocationAction = createAsyncThunk(
    "locations/addLocation",
    async(data) => {
        const response = await addLocation(data);
        return response;
    }
);


export const locationsSlice = createSlice({
    name: "locations",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        modify: (state, action) => {
            console.log('FROM MODIFY');
            var newData = state.value.map((location) => {
                if (location.id === action.payload.id)
                    return Object.assign({}, location, action.payload);
                return location;
            });
            state.value = newData;
        },
        changeRequireId: (state, action) => {
            //debugger;
            state.requireIdcheck[action.payload.index] = action.payload.check
        },

        changePriceAllBool: (state, action) => {
            //debugger;
            state.priceAllBool = action.payload
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: {
        [retrieveLocations.pending]: (state, action) => {
            state.status = "loading";
        },
        [retrieveLocations.fulfilled]: (state, action) => {
            state.status = "succeeded";
            // Add any fetched posts to the array
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                }
            } else {
                state.value = action.payload;
                state.value.forEach(function(val, i) {
                    state.requireIdcheck[i] = val.requiresUserId
                })
            }

        },
        [retrieveLocations.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        },
        [editLocation.pending]: (state, action) => {
            state.editStatus = "loading";
        },
        [editLocation.fulfilled]: (state, action) => {
            state.editStatus = "succeeded";
            // Add any fetched posts to the array
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                }
            } else {
                state.value = action.payload;
            }
        },
        [editLocation.rejected]: (state, action) => {
            state.editStatus = "failed";
            state.error = action.error.message;
        },
    },
});



export const { modify, changeRequireId, changePriceAllBool } = locationsSlice.actions;

export const selectAllLocations = (state) => state.locations.value;

export default locationsSlice.reducer;