import TopBar from "../../Components/TopBar.js";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    retrieveRTodaysReport,
    modifyDates,
    selectAllReports,
    retrieveRatingReportWDate,
} from "./ratingsReportSlice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { Table, Container, Button, Row, Col } from "react-bootstrap";
// import SearchBar from "../../Components/SearchBar";
import Loading from "../../Components/Loading";
import {generateExcelReport} from "../AdminReport/adminReportAPI";
import Popup from "reactjs-popup";
import Form from "react-bootstrap/Form";
import { CSVLink } from "react-csv";


const RatingsReport = () => {

    const reports = useSelector(selectAllReports);
    const status = useSelector((state) => state.ratingsReports.status);
    const editStatus = useSelector((state) => state.ratingsReports.editStatus);
    const startDate = useSelector((state) => state.ratingsReports.startDate);
    const endDate = useSelector((state) => state.ratingsReports.endDate);

    const dispatch = useDispatch();
    useEffect(() => {
        if (status === "idle") {
           
         dispatch(retrieveRTodaysReport());
         // console.log('From use effect: ', bookings)
        }
    }, [status, dispatch]);

    
    const onChange = (dates) => {
        console.log('These are dates: ', dates)
        dispatch(modifyDates(dates))
    };

    const handleClick = () => {
        dispatch(retrieveRatingReportWDate({startDate: startDate, endDate:endDate}))
    };

    const hadleExtractForm = (e)=>{
        debugger;
        var emailToSendTo = e.target.emailList.value;
        let reportsArr = [];
        Object.entries(reports).map((rep,index)=>{
            Object.entries(rep[1]).map((r,i) => {
                var obj = {
                    Location: rep[0],
                    ...r[1],
                }
            reportsArr.push(obj)
            })
        })
        debugger;
        var data = {emailToSendTo: emailToSendTo, report:reportsArr};
        generateExcelReport(data);
        console.log('Done !');
    }

    console.log('This is start date: ', typeof startDate);
    //console.log('This is endDate: ', endDate.toString());
    return (
        <div className="RatingsReports">
            <TopBar title="Customer Ratings Report"></TopBar>
            {/* <Popup
                trigger ={
                    <div>
                        <Button className="extractReport" type="button" variant="primary">Extract</Button>
                    </div>
                    }
                modal
            >
                <Form className="editForm" id={'generateReport'}  onSubmit={hadleExtractForm}>
                    <Form.Group as={Row} controlId="emailList" size="lg">
                        <Form.Label column sm="3">Email List</Form.Label>
                        <Col>
                            <Form.Control
                                autoFocus
                                type="text"
                            />
                        </Col>
                    </Form.Group>
                </Form>
                <button className="submitButton" form={'generateReport'} type="submit">
                    Submit
                </button>
            </Popup> */}
            <CSVLink
                filename='ratingReport.csv'
                data={reports}
                onClick={() => {
                    console.log("You downloaded the report"); 
                }}
                >
                <Button className="extractReport" type="button" variant="primary">Extract</Button>
                </CSVLink>
            <br></br>
            <br></br>
            <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
            />
            <Button className="addCourtButton" onClick={handleClick} type="button" variant="primary">Fetch data</Button>

            <br></br><br></br>
            <Container>
            {status === "loading" || editStatus === "loading" ? (
                <div className="loadingWrapper">
                    <Loading
                    color="#DD926A"
                    type="spin"
                    width="20%"
                    height="20%"
                    ></Loading>
                </div>
            ) :
            (<Table bordered hover responsive="sm">
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Location</th>
                        <th>Slot Time</th>
                        <th>Court Admin</th>
                        <th>Rating</th>
                        <th>Complaint List</th>
                        <th>Comment</th>
                    </tr>
                </thead>
                <tbody>
                {
                    Object.entries(reports).map((rep)=>{   
                        const complaintsList = rep[1].complaintsList.map(item => item).join(', ');   
                        return (
                            <tr>
                              <td>{rep[1].username}</td>
                              <td>{rep[1].location}</td>
                              <td>{rep[1].slotTime}</td>
                              <td>{rep[1].courtAdmin}</td>
                              <td>{rep[1].rating}</td>
                              <td>{complaintsList}</td>
                              <td>{rep[1].comment}</td>
                            </tr>
                        );
                    }) 
                }
                </tbody>
            </Table>)}
        </Container>
        </div>
    );
}

export default RatingsReport;