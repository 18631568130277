import TopBar from "./TopBar.js";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    retrieveCancellationsT,
    modifyDates,
    selectAllOverdues,
    retrieveCancellationsWDate,
    retrieveCancellationsForUser
} from "../Views/Overdue/overdueSlice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { Table, Container, Button, Row, Col } from "react-bootstrap";
import SearchBar from "./SearchBar";
import Loading from "./Loading";
import Popup from "reactjs-popup";
import Form from "react-bootstrap/Form";
import {generateExcelReport} from "../Views/AdminReport/adminReportAPI";

const OverdueTable = () => {

    const reports = useSelector(selectAllOverdues);
    const status = useSelector((state) => state.overdue.status);
    const editStatus = useSelector((state) => state.overdue.editStatus);
    const startDate = useSelector((state) => state.overdue.startDate);
    const endDate = useSelector((state) => state.overdue.endDate);

    var search = ""

    const dispatch = useDispatch();
    useEffect(() => {
        if (status === "idle") {
           debugger;
         dispatch(retrieveCancellationsT());
         // console.log('From use effect: ', bookings)
    }
  }, [status, dispatch]);

    
    const onChange = (dates) => {
        console.log('These are dates: ', dates)
        dispatch(modifyDates(dates))
    };

    const handleClick = () => {
        dispatch(retrieveCancellationsWDate({startDate: startDate, endDate:endDate}))
    }
    
    const hadleExtractForm = (e)=>{
        debugger;
        var emailToSendTo = e.target.emailList.value;
        let reportsArr = [];
        Object.entries(reports).map((rep,index)=>{
            if(rep[0]!='undefined'){
            var obj = {
                'Full Name': rep[1].Name,
                'Location Name': rep[1].locationName, 
                'Date': rep[1].date,
                'Slot price': rep[1].slotPrice,
                'Status': rep[1].status,
                'Comment': rep[1].comment
            }
            reportsArr.push(obj);
            }
        })
        debugger;
        var data = {emailToSendTo: emailToSendTo, report:reportsArr};
        generateExcelReport(data);
        console.log('Done !');
    }

    return (
        <div className="AdminReports">
            <TopBar title="All Overdue or Late Cancelled Bookings"></TopBar>
            <Popup
            trigger ={
                <div>
                    <Button className="extractReport" type="button" variant="primary">Extract</Button>
                </div>}
                modal
            >

            <Form className="editForm" id={'generateReport'}  onSubmit={hadleExtractForm}>
                    <Form.Group as={Row} controlId="emailList" size="lg">
                        <Form.Label column sm="3">Email List</Form.Label>
                        <Col>
                            <Form.Control
                                autoFocus
                                type="text"
                            />
                        </Col>
                    </Form.Group>
                    </Form>
                    <button className="submitButton" form={'generateReport'} type="submit">
                    Submit
                </button>

            </Popup>
            <br></br>
            <br></br>
            <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
            />
            <Button className="fetchData" onClick={handleClick} type="button" variant="primary">Fetch data</Button>

            <br></br><br></br>
            <Container>
            <SearchBar 
                handleSearch={(e)=>{search = e.target.value;}} 
                handleClick={()=>{dispatch(retrieveCancellationsForUser(search));}}
                keyPress={(e)=>{e.key === 'Enter' && dispatch(retrieveCancellationsForUser(search))}}
                placeholder="Search by Username or User ID">
            </SearchBar>


            {status === "loading" || editStatus === "loading" ? (
          <div className="loadingWrapper">
            <Loading
              color="#DD926A"
              type="spin"
              width="20%"
              height="20%"
            ></Loading>
          </div>
        ) :
            (<Table bordered hover responsive="sm">
                <thead>
                    <tr>
                        <th>Full Name</th>
                        <th>Location</th>
                        <th>Date</th>
                        <th>Slot Price</th>
                        <th>Status</th>
                        <th>Comment</th>
                    </tr>
                </thead>
                <tbody>
                {
                       Object.entries(reports).map((rep,index)=>{

                           //debugger;
                           if(rep[0]!='undefined')
                        return (
                            <tr key={index}>
                              <td>{rep[1].Name}</td>
                              <td>{rep[1].locationName}</td>
                              <td>{rep[1].date}</td>
                              <td>{rep[1].slotPrice}</td>
                              <td>
                      <Button variant="success"  className={rep[1].status == 'noShow'? "isNoShow": "isLateCancelled"}>
                        {" "}
                      </Button>{" "}
                    </td>
                            <td>{rep[1].comment}</td>
                            </tr>
                          );
                       }) 
                    }
                </tbody>
            </Table>)}
        </Container>
        </div>
    );
}

export default OverdueTable;