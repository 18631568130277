import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllCourtAdmins, modifyCourtAdmin , getUser, addingCourtAdmin } from "./courtAdminsAPI";

const initialState = {
    value: [],
    status: "idle",
    editStatus: "idle",
    addStatus: false,
    addStatusMessage: "idle",
    offset: 0,
    error: ""
};

export const addCourtAdmin = createAsyncThunk("users/addCourtAdmin", async(data) => {
    const response = await addingCourtAdmin(data);
    return response;
}
);

export const retrieveCourtAdmins = createAsyncThunk("users/getAllCourtAdmins", async() => {
    const response = await getAllCourtAdmins();
    return response;
});

export const retrieveCourtAdmin = createAsyncThunk("users/getCourtAdmin", async(data) => {
    data = {'search': data};
    const response = await getUser(data);
    return response;
});

export const editCourtAdmin = createAsyncThunk("users/modifyCourtAdmin", async(data) => {
        const response = await modifyCourtAdmin(data);
        return response;
    }
);

export const courtAdminsSlice = createSlice({
    name: "courtAdmins",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        modify: (state, action) => {
            var newData = state.value.courtAdminData.map((user) => {
                if (user.uid === action.payload.uid)
                    return Object.assign({}, user, action.payload);
                return user;
            });
            state.value = newData;
        },
        addLoc: (state, action) => {
            var newData = state.value.courtAdminData.map((user) => {
                if (user.uid === action.payload.uid)
                    // console.log("Here: ", Object.assign({}, user, action.payload));
                    return Object.assign({}, user, action.payload);
                return user;
            });
            state.value.courtAdminData = newData;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: {
        [retrieveCourtAdmins.pending]: (state, action) => {
            state.status = "loading";
            state.addStatus = false;
        },
        [retrieveCourtAdmins.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.addStatus = false;
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                }
            } else {
                state.value = action.payload;
            }
        },
        [retrieveCourtAdmins.rejected]: (state, action) => {
            state.status = "failed";
            state.addStatus = false;
            state.error = action.error.message;
        },
        [editCourtAdmin.pending]: (state, action) => {
            state.editStatus = "loading";
        },
        [editCourtAdmin.fulfilled]: (state, action) => {
            state.editStatus = "succeeded";
            state.addStatus = false;
            // Add any fetched posts to the array
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                }
            } else {
                state.value = action.payload;
            }
        },
        [editCourtAdmin.rejected]: (state, action) => {
            state.editStatus = "failed";
            state.addStatus = false;
            state.error = action.error.message;
        },

        [addCourtAdmin.pending]: (state, action) => {
            state.addStatus = false;
            state.addStatusMessage = "loading";
        },
        [addCourtAdmin.fulfilled]: (state, action) => {
            // Add any fetched posts to the array
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                    state.addStatus = false;
                    state.addStatusMessage = "failed";
                    state.error = action.payload.message;
                } else {
                    state.addStatus = true;
                    state.addStatusMessage = "succeeded";
                }
            }
        },
        [addCourtAdmin.rejected]: (state, action) => {
            state.addStatus = false;
            state.addStatusMessage = "failed";
            state.error = action.error.message;
        },

        [retrieveCourtAdmin.pending]: (state, action) => {
            state.status = "loading";
            state.addStatus = false;
        },
        [retrieveCourtAdmin.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.addStatus = false;
            if (action.payload.message){
                if (action.payload.message === "Unathorized"){
                    state.value = []
                }
            } else {
                state.offset = 0;
                state.value = action.payload;
            }
        },
        [retrieveCourtAdmin.rejected]: (state, action) => {
            state.status = "failed";
            state.addStatus = false;
            state.error = action.error.message;
        },
    },
});

export const { modify, addLoc } = courtAdminsSlice.actions;

export const selectAllCourtAdmins = (state) => state.courtAdmins.value;
export const { toggleUserEditMode } = courtAdminsSlice.actions;

export default courtAdminsSlice.reducer;